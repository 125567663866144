import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import ZHeader from '@modules/Layout/Zumba/ZHeader.vue';
import MenuButton from "@modules/Layout/nav/header/hamburger/Zumba/MenuButton.vue";
const HeaderComponent = defineComponent({
    name: 'HeaderComponent',
    components: {
        MenuButton,
        ZHeader
    }
});
if (document.getElementById('header-component')) {
    createApp(HeaderComponent).mount('#header-component');
}
