import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["href", "title", "data-ga-label", "data-ga4-details"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'ZumbaAppButton',
    props: {
        t: {
            type: Object,
            default: () => ({
                zumbaAppTitleCaseText: 'Zumba App',
                isZin: false,
            })
        },
        gaLabel: { type: String, default: '' },
        isZin: { type: Boolean, default: false },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("a", {
                    class: "ocn-list-link",
                    href: props.isZin ? '/zumba-app-zin' : '/app-subscription',
                    title: __props.t.zumbaAppTitleCaseText,
                    "data-ga-category": "Navigation Menu",
                    "data-ga-action": "Zumba App",
                    "data-ga-label": props.gaLabel,
                    "data-ga4-event-name": "navigation_menu",
                    "data-ga4-action": "Zumba App",
                    "data-ga4-details": props.gaLabel
                }, _toDisplayString(__props.t.zumbaAppTitleCaseText), 9, _hoisted_2)
            ]));
        };
    }
});
