import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import ZMenu from "@modules/Layout/nav/header/hamburger/Zumba/Menu.vue";
import UserMenu from "@modules/Layout/nav/header/hamburger/Zumba/UserMenu.vue";
import Classes from "@modules/Layout/nav/header/hamburger/Zumba/Classes.vue";
import Shop from "@modules/Layout/nav/header/hamburger/Zumba/Shop.vue";
import About from "@modules/Layout/nav/header/hamburger/Zumba/About.vue";
import Sessions from "@modules/Layout/nav/header/hamburger/Zumba/Sessions.vue";
import Support from "@modules/Layout/nav/header/hamburger/Zumba/Support.vue";
import Instructors from "@modules/Layout/nav/header/hamburger/Zumba/Instructors.vue";
import Gym from "@modules/Layout/nav/header/hamburger/Zumba/Gym.vue";
import Tag from '@components/Tags/Tag.vue';
const MenuContainer = defineComponent({
    name: 'MenuContainer',
    components: {
        ZMenu,
        UserMenu,
        Classes,
        Shop,
        About,
        Sessions,
        Support,
        Instructors,
        Gym,
        Tag
    }
});
if (document.getElementById('z-menu-container')) {
    createApp(MenuContainer).mount('#z-menu-container');
}
