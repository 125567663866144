import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["href", "data-ga-label", "innerHTML"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Sessions',
    props: {
        active: { type: Object, default() { return {}; } },
        t: { type: Object, default: () => ({ jamSessionsText: 'ZIN&trade; Jam Sessions' }) },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        hasPreMembershipLinksOnly: { type: String, default: '' }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (props.isAuthenticated)
                ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                    (props.hasPreMembershipLinksOnly)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: "ocn-list-item__no-access ocn-list-link",
                            innerHTML: __props.t.jamSessionsText
                        }, null, 8, _hoisted_2))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: '/' + props.currentUserLocale + '/zin_jams',
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/zin_jams']) }]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Instructors_Jam Sessions",
                            "data-ga-label": props.gaLabel,
                            innerHTML: __props.t.jamSessionsText
                        }, null, 10, _hoisted_3))
                ]))
                : _createCommentVNode("", true);
        };
    }
});
