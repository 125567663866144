import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["href", "data-ga-label", "data-ga4-details", "innerHTML"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Sessions',
    props: {
        t: {
            type: Object,
            default: () => ({
                zinJamSessionsText: 'ZIN™ Jam Sessions'
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (props.isAuthenticated)
                ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                    _createElementVNode("a", {
                        href: '/' + props.currentUserLocale + '/trainings/zumba_jammer',
                        "data-ga-category": "Navigation Menu",
                        "data-ga-action": "Instructors_Jam Sessions",
                        "data-ga-label": props.gaLabel,
                        "data-ga4-event-name": "navigation_menu",
                        "data-ga4-action": "Instructors_Jam Sessions",
                        "data-ga4-details": props.gaLabel,
                        innerHTML: props.t.zinJamSessionsText
                    }, null, 8, _hoisted_2)
                ]))
                : _createCommentVNode("", true);
        };
    }
});
