import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_5 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_6 = { class: "ocn-list-item" };
const _hoisted_7 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_8 = { class: "ocn-list-item" };
const _hoisted_9 = ["href", "data-ga-label", "data-ga4-details"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Instructors',
    props: {
        active: { type: Object, default() { return {}; } },
        t: {
            type: Object,
            default: () => ({
                instructorsText: 'Instructors',
                aboutInstructorTrainingsText: 'Instructor Trainings',
                becomeAnInstructorText: 'Become an Instructor',
                findATrainingText: 'Find a Training',
                trainingTypeText: 'Training Types',
                viewAllTrainingText: 'View All Training',
                findAnInstructorText: 'Find an Instructor',
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        userHasBenefitSpecialtyTrainingAccess: { type: String, default: '' },
        isBotBaiCoronavirusEnabled: { type: String, default: '' },
        isMaximizeb1SitenavTrainingtypesEnabled: { type: String, default: '' },
        isOnlineCountryUs: { type: String, default: '' }
    },
    setup(__props) {
        const isEmpty = (obj) => Object.keys(obj ?? {}).length == 0;
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("li", _hoisted_1, [
                    _createElementVNode("a", {
                        class: _normalizeClass(["more-below ocn-list-link", { 'js-active-item inactive-item': !isEmpty(props.active?.['Instructors']) }]),
                        href: "#",
                        title: __props.t.aboutInstructorTrainingsText
                    }, _toDisplayString(__props.t.aboutInstructorTrainingsText), 11, _hoisted_2),
                    _createElementVNode("ul", {
                        class: "ocn-subnav",
                        style: _normalizeStyle(!isEmpty(props.active?.['Instructors']) ? 'display: block;' : '')
                    }, [
                        _createElementVNode("li", _hoisted_3, [
                            (props.isBotBaiCoronavirusEnabled && !props.userHasBenefitSpecialtyTrainingAccess && props.isOnlineCountryUs)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: '/' + props.currentUserLocale + '/online-program',
                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/online-program']) }]),
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Instructors_Become an Instructor",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "navigation_menu",
                                    "data-ga4-action": "Instructors_Become an Instructor",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(__props.t.becomeAnInstructorText), 11, _hoisted_4))
                                : (!props.userHasBenefitSpecialtyTrainingAccess)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        href: '/' + props.currentUserLocale + '/become-a-zumba-instructor',
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/become-a-zumba-instructor']) }]),
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Instructors_Become an Instructor",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Instructors_Become an Instructor",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(__props.t.becomeAnInstructorText), 11, _hoisted_5))
                                    : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("li", _hoisted_6, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/training/nearby?type%5B0%5D=zumba_basic_1&type%5B1%5D=jump_start_gold&type%5B2%5D=jump_start_kids',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/training/nearby']) }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Instructors_Find A Training",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructors_Find A Training",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(__props.t.findATrainingText), 11, _hoisted_7)
                        ]),
                        _createElementVNode("li", _hoisted_8, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/trainings',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/trainings']) }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Instructors_View All Trainings",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructors_View All Trainings",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.isMaximizeb1SitenavTrainingtypesEnabled
                                ? __props.t.trainingTypeText
                                : __props.t.viewAllTrainingText), 11, _hoisted_9)
                        ]),
                        _renderSlot(_ctx.$slots, "sessions")
                    ], 4)
                ]),
                _renderSlot(_ctx.$slots, "default")
            ], 64));
        };
    }
});
