import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_2 = ["href", "data-ga-label"];
const _hoisted_3 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_4 = ["href", "data-ga-label"];
const _hoisted_5 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_6 = ["href", "data-ga-label"];
const _hoisted_7 = {
    key: 3,
    class: "ocn-list-item"
};
const _hoisted_8 = ["href", "data-ga-label"];
const _hoisted_9 = {
    key: 4,
    class: "ocn-list-item"
};
const _hoisted_10 = ["href", "data-ga-label"];
const _hoisted_11 = {
    key: 5,
    class: "ocn-list-item"
};
const _hoisted_12 = ["href", "data-ga-label"];
const _hoisted_13 = {
    key: 6,
    class: "ocn-list-item"
};
const _hoisted_14 = ["href", "data-ga-label"];
const _hoisted_15 = {
    key: 7,
    class: "ocn-list-item"
};
const _hoisted_16 = ["href", "data-ga-label"];
const _hoisted_17 = {
    key: 8,
    class: "ocn-list-item"
};
const _hoisted_18 = ["href", "data-ga-label"];
const _hoisted_19 = {
    key: 9,
    class: "ocn-list-item"
};
const _hoisted_20 = ["href", "data-ga-label"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Gym',
    props: {
        active: { type: Object, default() { return {}; } },
        t: {
            type: Object,
            default: () => ({
                myAnnouncementsText: 'My Announcements',
                marketingMaterialsText: 'Marketing Materials',
                findAnInstructorText: 'Find an Instructor',
                manageInstructorsText: 'Manage Instructors',
                hostATrainingText: 'Host a Training',
                zumbaForGymsText: 'Zumba For Gyms',
                gymAuditionKitText: 'Gym Audition Kit',
                manageMyLocationsText: 'Manage Locations',
                accountSummaryText: 'Account Summary',
                gymsProfileText: 'Gyms Profile',
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        canManageGymProfile: { type: String, default: '' },
        canManageLinkedGymInstructors: { type: String, default: '' }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/announcement'] }]),
                            href: '/' + props.currentUserLocale + '/announcement',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Announcements",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.myAnnouncementsText), 11, _hoisted_2)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/gym-marketing'] }]),
                            href: '/' + props.currentUserLocale + '/gym-marketing',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Marketing Materials",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.marketingMaterialsText), 11, _hoisted_4)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/instructor'] }]),
                            href: '/' + props.currentUserLocale + '/instructor',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Find an Instructor",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.findAnInstructorText), 11, _hoisted_6)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageLinkedGymInstructors)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/gym/instructor'] }]),
                            href: '/' + props.currentUserLocale + '/gym/instructor',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Manage Instructors",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.manageInstructorsText), 11, _hoisted_8)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/clubs/host_a_training'] }]),
                            href: '/' + props.currentUserLocale + '/clubs/host_a_training',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Host a Training",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.hostATrainingText), 11, _hoisted_10)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/clubs/business_perks'] }]),
                            href: '/' + props.currentUserLocale + '/clubs/business_perks',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Zumba for Gyms",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.zumbaForGymsText), 11, _hoisted_12)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/gym/auditionkits'] }]),
                            href: '/' + props.currentUserLocale + '/gym/auditionkits/',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Audition Kit",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.gymAuditionKitText), 11, _hoisted_14)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/gym/location'] }]),
                            href: '/' + props.currentUserLocale + '/gym/location',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Manage Locations",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.manageMyLocationsText), 11, _hoisted_16)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/account'] }]),
                            href: '/' + props.currentUserLocale + '/account',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Account Summary",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.accountSummaryText), 11, _hoisted_18)
                    ]))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/gym/account'] }]),
                            href: '/' + props.currentUserLocale + '/gym/account',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Gym Profile",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.gymsProfileText), 11, _hoisted_20)
                    ]))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
