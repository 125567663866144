import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, unref as _unref, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "data-ga-label"];
const _hoisted_5 = { class: "ocn-list-item" };
const _hoisted_6 = ["href", "data-ga-label"];
const _hoisted_7 = { class: "ocn-list-item" };
const _hoisted_8 = ["href", "data-ga-label"];
const _hoisted_9 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_10 = ["href", "data-ga-label"];
const _hoisted_11 = { class: "ocn-list-item" };
const _hoisted_12 = ["href", "data-ga-label"];
const _hoisted_13 = { class: "ocn-list-item" };
const _hoisted_14 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_15 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_16 = ["href", "data-ga-label"];
const _hoisted_17 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_18 = ["title"];
const _hoisted_19 = { class: "ocn-list-item" };
const _hoisted_20 = ["href", "data-ga-label"];
const _hoisted_21 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_22 = ["href", "data-ga-label"];
const _hoisted_23 = { class: "ocn-list-item" };
const _hoisted_24 = ["href", "data-ga-label"];
const _hoisted_25 = { class: "ocn-list-item" };
const _hoisted_26 = ["href", "data-ga-label"];
const _hoisted_27 = { class: "ocn-list-item" };
const _hoisted_28 = ["href", "data-ga-label"];
const _hoisted_29 = { class: "ocn-list-item" };
const _hoisted_30 = ["href", "data-ga-label"];
const _hoisted_31 = { class: "ocn-list-item" };
const _hoisted_32 = ["href", "data-ga-label"];
const _hoisted_33 = { class: "ocn-list-item" };
const _hoisted_34 = ["href", "data-ga-label"];
const _hoisted_35 = ["href", "data-ga-label"];
import { todayISO } from '@ts/Util/datetime';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Classes',
    props: {
        active: { type: Object, default() { return {}; } },
        t: {
            type: Object,
            default: () => ({
                aboutOurClassesText: 'About our Classes',
                findAnInPersonClassText: 'Find An In-Person Class',
                findAVirtualClassText: 'Find a Virtual Class',
                findAClassText: 'Find a Class',
                findAnEventText: 'Find an Event',
                becomeAnInstructorText: 'Become an Instructor',
                beginnerVideosText: 'Beginner Videos',
                buySixWeekProgramText: 'Buy 6 Week Program',
                findAnInstructorText: 'Find an Instructor',
                localClassesTitleCaseText: 'Local Classes',
                inPersonClassesTitleCaseText: 'In Person',
                zoomClassesTitleCaseText: 'Zoom',
                eventsTitleCaseText: 'Events',
                getTheAppTitleCaseText: 'Get the App',
                virtualClassesTitleCaseText: 'Virtual+ Classes',
                zumbaAppTitleCaseText: 'Zumba App',
                findInstructorsText: 'Find Instructors',
                postAJobText: 'Post A Job',
                hireAnInstructorText: 'Hire an Instructor',
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        isSixWeekLaunchEnabled: { type: String, default: '' },
        onlineClassBaseUrl: { type: String, default: '' },
        sixWeekSplashPageUrl: { type: String, default: '' },
        classesText: { type: String, default: 'Classes' },
        classLocatorEnabled: { type: String, default: '' },
        isCirclUrl: { type: String, default: '' },
        isZumbaAppMenuLaunchEnabled: { type: String, default: '' },
        isJobBoardFormEnabled: { type: String, default: '' },
        isGymPostAJobEnabled: { type: Boolean, default: false },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (props.isZumbaAppMenuLaunchEnabled)
                ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                    _createElementVNode("a", {
                        class: _normalizeClass(["more-below ocn-list-link", { 'js-active-item inactive-item': props.active?.['LocalClasses'] }]),
                        href: "#",
                        title: __props.t.localClassesTitleCaseText
                    }, _toDisplayString(__props.t.localClassesTitleCaseText), 11, _hoisted_2),
                    _createElementVNode("ul", {
                        class: "ocn-subnav",
                        style: _normalizeStyle(props.active?.['LocalClasses'] ? 'display: block;' : '')
                    }, [
                        _createElementVNode("li", _hoisted_3, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/class_search?searchType=in_person',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['LocalClasses']?.['/class_search?searchType=in_person'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Find an In Person Class",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.inPersonClassesTitleCaseText), 11, _hoisted_4)
                        ]),
                        _createElementVNode("li", _hoisted_5, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/class_search?searchType=virtual',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['LocalClasses']?.['/class_search_virtual'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Find a Zoom Class",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.zoomClassesTitleCaseText), 11, _hoisted_6)
                        ]),
                        _createElementVNode("li", _hoisted_7, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/class_search?searchType=vod',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['LocalClasses']?.['/class_search_vod'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Find a VOD Class",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.VODClassesTitleCaseText), 11, _hoisted_8)
                        ]),
                        (props.classLocatorEnabled)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                                _createElementVNode("a", {
                                    href: '/' + props.currentUserLocale + '/pages/class',
                                    class: _normalizeClass([{ 'js-active-item active-item': props.active?.['LocalClasses']?.['/pages/class'] }, "ocn-list-link"]),
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Classes_Find a Class",
                                    "data-ga-label": props.gaLabel
                                }, _toDisplayString(__props.t.classTypesText), 11, _hoisted_10)
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("li", _hoisted_11, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/event/nearMe',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['LocalClasses']?.['/event/nearMe'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Find an Event",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.eventsTitleCaseText), 11, _hoisted_12)
                        ]),
                        _createElementVNode("li", _hoisted_13, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/instructor',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['LocalClasses']?.['/instructor'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Find an Instructor",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructors_Find an Instructor",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(__props.t.findInstructorsText), 11, _hoisted_14)
                        ]),
                        (props.isJobBoardFormEnabled)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
                                _createElementVNode("a", {
                                    href: '/' + props.currentUserLocale + '/hireinstructor' + (props.isGymPostAJobEnabled ? '#job-board-form-section' : ''),
                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['LocalClasses']?.['/hireinstructor'] }]),
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Classes_Hire an Instructor",
                                    "data-ga-label": props.gaLabel
                                }, _toDisplayString(props.isGymPostAJobEnabled ? __props.t.postAJobText : __props.t.hireAnInstructorText), 11, _hoisted_16)
                            ]))
                            : _createCommentVNode("", true)
                    ], 4)
                ]))
                : (_openBlock(), _createElementBlock("li", _hoisted_17, [
                    _createElementVNode("a", {
                        class: _normalizeClass(["more-below ocn-list-link", { 'js-active-item inactive-item': props.active?.['Classes'] }]),
                        href: "#",
                        title: __props.t.classesText
                    }, _toDisplayString(__props.t.classesText), 11, _hoisted_18),
                    _createElementVNode("ul", {
                        class: "ocn-subnav",
                        style: _normalizeStyle(props.active?.['Classes'] ? 'display: block;' : '')
                    }, [
                        _createElementVNode("li", _hoisted_19, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/pages/class',
                                class: _normalizeClass([{ 'js-active-item inactive-item': props.active?.['Classes']?.['/pages/class'] }, "ocn-list-link"]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_About our Classes",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.aboutOurClassesText), 11, _hoisted_20)
                        ]),
                        (props.classLocatorEnabled)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_21, [
                                _createElementVNode("a", {
                                    href: '/' + props.currentUserLocale + '/class_search' + (props.isCirclUrl ? '?class-type=circl-mobility' : ''),
                                    class: _normalizeClass([{ 'js-active-item inactive-item': props.active?.['Classes']?.['/party/nearMe'] }, "ocn-list-link"]),
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Classes_Find a Class",
                                    "data-ga-label": props.gaLabel
                                }, _toDisplayString(__props.t.findAClassText), 11, _hoisted_22)
                            ]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("li", _hoisted_23, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/party/nearMe?date=' + _unref(todayISO)(),
                                        class: _normalizeClass([{ 'js-active-item inactive-item': props.active?.['Classes']?.['/party/nearMe'] }, "ocn-list-link"]),
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find a Class",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(__props.t.findAnInPersonClassText), 11, _hoisted_24)
                                ]),
                                _createElementVNode("li", _hoisted_25, [
                                    _createElementVNode("a", {
                                        href: props.onlineClassBaseUrl + '/?locale=' + props.currentUserLocale.replace('-', '_'),
                                        class: "ocn-list-link",
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find a Virtual Class",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(__props.t.findAVirtualClassText), 9, _hoisted_26)
                                ])
                            ], 64)),
                        _createElementVNode("li", _hoisted_27, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/event/nearMe',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Classes']?.['/event/nearMe'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Find an Event",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.findAnEventText), 11, _hoisted_28)
                        ]),
                        _createElementVNode("li", _hoisted_29, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/instructor',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Classes']?.['/instructor'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Find an Instructor",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.findAnInstructorText), 11, _hoisted_30)
                        ]),
                        _createElementVNode("li", _hoisted_31, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/become-a-zumba-instructor',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Classes']?.['/become-a-zumba-instructor'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Become an Instructor",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.becomeAnInstructorText), 11, _hoisted_32)
                        ]),
                        _createElementVNode("li", _hoisted_33, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/beginners',
                                class: "ocn-list-link",
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Beginner Videos",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(__props.t.beginnerVideosText), 9, _hoisted_34)
                        ]),
                        _createElementVNode("li", null, [
                            (props.isSixWeekLaunchEnabled && props.currentUserLocale == 'en-US')
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: props.sixWeekSplashPageUrl,
                                    class: "ocn-list-link",
                                    target: "_blank",
                                    rel: "noopener",
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Classes_SIX_WEEK Splash Page",
                                    "data-ga-label": props.gaLabel
                                }, _toDisplayString(__props.t.buySixWeekProgramText), 9, _hoisted_35))
                                : _createCommentVNode("", true)
                        ])
                    ], 4)
                ]));
        };
    }
});
