import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "data-ga-label"];
const _hoisted_5 = ["href", "data-ga-label"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'ZumbaApp',
    props: {
        active: { type: Object, default() { return {}; } },
        t: {
            type: Object,
            default: () => ({
                getTheAppTitleCaseText: 'Get the App',
                virtualClassesTitleCaseText: 'Virtual+ Classes',
                zumbaAppTitleCaseText: 'Zumba App',
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        classesText: { type: String, default: 'Classes' },
        zumbaAppStoreUrl: { type: String, default: '' },
        virtualPlusSubscriptionUrl: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("a", {
                    class: _normalizeClass(["more-below ocn-list-link", { 'js-active-item inactive-item': props.active?.['ZumbaApp'] }]),
                    href: "#",
                    title: __props.t.zumbaAppTitleCaseText
                }, _toDisplayString(__props.t.zumbaAppTitleCaseText), 11, _hoisted_2),
                _createElementVNode("ul", {
                    class: "ocn-subnav",
                    style: _normalizeStyle(props.active?.['ZumbaApp'] ? 'display: block;' : '')
                }, [
                    _createElementVNode("li", _hoisted_3, [
                        _createElementVNode("a", {
                            href: props.zumbaAppStoreUrl,
                            target: "_blank",
                            rel: "noopener",
                            class: "ocn-list-link",
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Zumba_App get_app",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.getTheAppTitleCaseText), 9, _hoisted_4)
                    ]),
                    _createElementVNode("li", null, [
                        _createElementVNode("a", {
                            href: props.virtualPlusSubscriptionUrl,
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ZumbaApp']?.['app-subscription/signup'] }]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Zumba_App subscription_url",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.virtualPlusClassesTitleCaseText), 11, _hoisted_5)
                    ])
                ], 4)
            ]));
        };
    }
});
