import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_5 = { class: "ocn-list-item" };
const _hoisted_6 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_7 = { class: "ocn-list-item" };
const _hoisted_8 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_9 = { class: "ocn-list-item" };
const _hoisted_10 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_11 = { class: "ocn-list-item" };
const _hoisted_12 = ["data-ga-label", "data-ga4-details"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'About',
    props: {
        active: { type: Object, default() { return {}; } },
        t: {
            type: Object,
            default: () => ({
                aboutText: 'About',
                aboutZumbaText: 'About Zumba',
                zumbaBlogText: 'Zumba® Blog',
                zumbaForGymsText: 'Zumba® for Gyms',
                instructorTrainingsText: 'Instructor Trainings',
                careersText: 'Careers',
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' }
    },
    setup(__props) {
        const isEmpty = (obj) => Object.keys(obj ?? {}).length == 0;
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("a", {
                    class: _normalizeClass(["more-below ocn-list-link", { 'js-active-item inactive-item': !isEmpty(props.active?.['About']) }]),
                    href: "#",
                    title: __props.t.aboutText
                }, _toDisplayString(__props.t.aboutText), 11, _hoisted_2),
                _createElementVNode("ul", {
                    class: "ocn-subnav",
                    style: _normalizeStyle(!isEmpty(props.active?.['About']) ? 'display: block;' : '')
                }, [
                    _createElementVNode("li", _hoisted_3, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/about',
                            class: _normalizeClass([{ 'js-active-item active-item': props.active?.['About']?.['/about'] }, "ocn-list-link"]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "About_About Zumba",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "About_About Zumba",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.aboutZumbaText), 11, _hoisted_4)
                    ]),
                    _createElementVNode("li", _hoisted_5, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/blog',
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['About']?.['/blog'] }]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "About_Zumba Blog",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "About_Zumba Blog",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.zumbaBlogText), 11, _hoisted_6)
                    ]),
                    _createElementVNode("li", _hoisted_7, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/clubs',
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['About']?.['/clubs'] }]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "About_Zumba for Gyms",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "About_Zumba for Gyms",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.zumbaForGymsText), 11, _hoisted_8)
                    ]),
                    _createElementVNode("li", _hoisted_9, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/trainings',
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['About']?.['/trainings'] }]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "About_Instructor Trainings",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "About_Instructor Trainings",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.instructorTrainingsText), 11, _hoisted_10)
                    ]),
                    _createElementVNode("li", _hoisted_11, [
                        _createElementVNode("a", {
                            href: "https://careers.zumba.com/",
                            class: "ocn-list-link",
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "About_Careers",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "About_Careers",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.careersText), 9, _hoisted_12)
                    ])
                ], 4)
            ]));
        };
    }
});
