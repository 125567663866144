import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["href"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Shop',
    props: {
        navigationShopUrl: { type: String, default: '' },
        t: { type: Object, default: () => ({ shopText: 'Shop Clothing' }) },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("a", {
                    class: "ocn-list-link",
                    href: props.navigationShopUrl,
                    title: "Shop"
                }, _toDisplayString(__props.t.shopText), 9, _hoisted_2)
            ]));
        };
    }
});
