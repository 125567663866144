import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, unref as _unref, Fragment as _Fragment } from "vue";
import _imports_0 from '@icons/logo.png';
import _imports_1 from '@icons/logo@2x.png';
const _hoisted_1 = _imports_1 + ' 2x';
const _hoisted_2 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_3 = { class: "gh-menu-left" };
const _hoisted_4 = { class: "gh-secondary-nav" };
const _hoisted_5 = {
    key: 0,
    class: "gh-secondary-nav-item"
};
const _hoisted_6 = { key: 1 };
const _hoisted_7 = {
    key: 0,
    class: "gh-main-nav-item"
};
const _hoisted_8 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_9 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_10 = {
    key: 1,
    class: "gh-main-nav-item"
};
const _hoisted_11 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_12 = {
    id: "nav-classes",
    class: "text-left dropdown"
};
const _hoisted_13 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_14 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_15 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_16 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_17 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_18 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_19 = { key: 0 };
const _hoisted_20 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_21 = {
    key: 2,
    class: "gh-main-nav-item"
};
const _hoisted_22 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_23 = {
    id: "nav-classes",
    class: "text-left dropdown"
};
const _hoisted_24 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_25 = { key: 0 };
const _hoisted_26 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_27 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_28 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_29 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_30 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_31 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_32 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_33 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_34 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_35 = {
    key: 3,
    class: "gh-main-nav-item"
};
const _hoisted_36 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_37 = {
    id: "nav-trainings",
    class: "text-left dropdown"
};
const _hoisted_38 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_39 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_40 = {
    key: 4,
    class: "gh-main-nav-item"
};
const _hoisted_41 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_42 = { class: "gh-main-nav-item" };
const _hoisted_43 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_44 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_45 = {
    id: "nav-trainings",
    class: "text-left dropdown"
};
const _hoisted_46 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_47 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_48 = { key: 0 };
const _hoisted_49 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_50 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_51 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_52 = { class: "gh-main-nav-item" };
const _hoisted_53 = ["href", "title", "data-ga-label", "data-ga4-details"];
import { reactive, onMounted, computed } from 'vue';
import MenuButton from '../nav/header/hamburger/Zumba/MenuButton.vue';
import Profile from '../Profile.vue';
import Sessions from './Sessions.vue';
import { todayISO } from '@ts/Util/datetime';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ZHeader',
    props: {
        t: {
            type: Object,
            default: () => ({
                navigationText: 'Navigation',
                myZinHomeText: 'My Zin Home',
                myDashboardText: 'My Dashboard',
                joinZinText: 'Join ZIN™',
                updatePaymentText: 'Update Payment',
                instructorTrainingsText: 'Instructor Trainings',
                sixWeekProgramText: '6 Week Program',
                myTrainingsText: 'My Trainings',
                manageMyAccountText: 'Manage My Account',
                myAccountText: 'My Account',
                logInText: 'Log In',
                signInText: 'Sign In',
                signOutText: 'Sign Out',
                myAnnouncementsText: 'My Announcements',
                marketingMaterialsText: 'Marketing Materials',
                findAnInstructorGymText: 'Find An Instructor',
                manageInstructorsText: 'Manage Instructors',
                hostATrainingText: 'Host a Training',
                zumbaForGymsText: 'Zumba For Gyms',
                gymAuditionKitText: 'Gym Audition Kit',
                manageLocationsText: 'Manage Locations',
                accountSummaryText: 'Account Summary',
                gymsProfileText: 'Gyms Profile',
                splashPageMenuItemText: '6 Week Program',
                classesTitleCaseText: 'Classes',
                aboutOurClassesText: 'About our Classes',
                findAnInPersonClassText: 'Find An In-Person Class',
                findAVirtualClassText: 'Find a Virtual Class',
                findAClassText: 'Find a Class',
                findAnEventText: 'Find an Event',
                findAnInstructorText: 'Find an Instructor',
                becomeAnInstructorText: 'Become an Instructor',
                shopText: 'Shop Clothing',
                instructorTrainingsDetailsText: 'Instructor Trainings',
                findATrainingText: 'Find a Training',
                trainingTypesText: 'Training Types',
                viewAllTrainingsText: 'View All Trainings',
                zinJamSessionsText: 'ZIN™ Jam Sessions',
                localClassesTitleCaseText: 'Local Classes',
                inPersonClassesTitleCaseText: 'In Person',
                zoomClassesTitleCaseText: 'Zoom',
                eventsTitleCaseText: 'Events',
                getTheAppTitleCaseText: 'Get the App',
                virtualClassesTitleCaseText: 'Virtual+ Classes',
                zumbaAppTitleCaseText: 'Zumba App',
                findInstructorsText: 'Find Instructors',
                hireAnInstructorText: 'Hire an Instructor',
                postAJobText: 'Post A Job',
            })
        },
        canAccessMemberMenu: { type: String, default: '' },
        canAccessSixWeeksProgramProfile: { type: String, default: '' },
        canConsumerViewDashboard: { type: String, default: '' },
        canGymOwnerViewDashboard: { type: String, default: '' },
        canManageGymProfile: { type: String, default: '' },
        canManageLinkedGymInstructors: { type: String, default: '' },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        gymActiveLink: { type: String, default: '' },
        headerFooterStyle: { type: String, default: 'full' },
        isAuthenticated: { type: String, default: '' },
        isBotBaiCoronavirusEnabled: { type: String, default: '' },
        isCmsShopBrandingZwEnabled: { type: String, default: '' },
        isMaximizeb1SitenavTrainingtypesEnabled: { type: String, default: '' },
        isOnlineCountryUs: { type: String, default: '' },
        isSixWeekLaunchEnabled: { type: String, default: '' },
        isSixWeekTopNavLinkEnabled: { type: String, default: '' },
        isTrainedNonMember: { type: String, default: '' },
        itemClass: { type: String, default: '' },
        linkClass: { type: String, default: '' },
        localizationUser: { type: String, default: '' },
        loginUrl: { type: String, default: '' },
        signUpUrl: { type: String, default: '' },
        logoSrc: { type: String, default: '' },
        logoSrcSet: { type: String, default: '' },
        mtvHeaderEnabled: { type: String, default: '' },
        navigationText: { type: String, default: 'Navigation' },
        onlineClassBaseUrl: { type: String, default: '' },
        profileName: { type: String, default: '' },
        shopUrl: { type: String, default: '' },
        shouldShowJoinLink: { type: String, default: '' },
        userHasDelinquentMembership: { type: String, default: '' },
        sixWeekSplashPageUrl: { type: String, default: '' },
        userHasBenefitMemberPortal: { type: String, default: '' },
        userHasBenefitSixWeekProgram: { type: String, default: '' },
        userHasBenefitSpecialtyTrainingAccess: { type: String, default: '' },
        zinResponsiveLink: { type: String, default: '' },
        isZjRefactorNavigationEnabled: { type: String, default: '' },
        isSupportedSixWeekLocale: { type: String, default: '' },
        classLocatorEnabled: { type: String, default: '' },
        isCirclUrl: { type: String, default: '' },
        isBaiUrl: { type: String, default: '' },
        isTrainingUrl: { type: String, default: '' },
        isZumbaAppMenuLaunchEnabled: { type: String, default: '' },
        isZumbaAppNoMenuButtonEnabled: { type: Boolean, default: false },
        isZin: { type: Boolean, default: false },
        appStoreUrl: { type: String, default: '' },
        playStoreUrl: { type: String, default: '' },
        downloadAppUrl: { type: String, default: '' },
        appLandingUrl: { type: String, default: '' },
        virtualPlusSubscriptionUrl: { type: String, default: '' },
        isPrePaymentAccessEnabled: { type: String, default: '' },
        isJobBoardFormEnabled: { type: String, default: '' },
        hideMobileMenu: { type: Boolean, default: false },
        isGymPostAJobEnabled: { type: Boolean, default: false }
    },
    setup(__props) {
        const props = __props;
        const state = reactive({
            isSticky: false
        });
        const hideNavigationElements = computed(() => {
            return props.headerFooterStyle === 'minimal';
        });
        const getAppUrl = computed(() => {
            let url = props.downloadAppUrl;
            const isIos = navigator.userAgent.match(/iP(ad|hone|od)/i) !== null;
            const isAndroid = navigator.userAgent.match(/Android/i) !== null;
            if (isAndroid) {
                url = props.playStoreUrl;
            }
            if (isIos) {
                url = props.appStoreUrl;
            }
            return url;
        });
        onMounted(() => {
            addStickyObserver();
        });
        const addStickyObserver = () => {
            const headerElement = document.getElementById('header-toggle-component');
            const observerOptions = { threshold: 0.1 };
            const toggleIsSticky = entries => state.isSticky = entries[0].intersectionRatio < 0.1;
            const observer = new IntersectionObserver(toggleIsSticky, observerOptions);
            if (!headerElement)
                return;
            observer.observe(headerElement);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("header", {
                class: _normalizeClass(["global-header", {
                        'sticky': state.isSticky,
                        'no-clickable': __props.hideMobileMenu
                    }])
            }, [
                (props.isCmsShopBrandingZwEnabled)
                    ? _renderSlot(_ctx.$slots, "zumbaheaderlogo", { key: 0 })
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: '/' + props.localizationUser,
                        title: "Zumba Fitness",
                        class: "gh-logo",
                        "data-ga-category": "Global Logo",
                        "data-ga-action": "Click",
                        "data-ga-label": props.gaLabel,
                        "data-ga4-event-name": "global_logo",
                        "data-ga4-action": "Click",
                        "data-ga4-details": props.gaLabel
                    }, _cache[0] || (_cache[0] = [
                        _createElementVNode("img", {
                            srcset: _hoisted_1,
                            src: _imports_0,
                            alt: "Zumba Fitness",
                            width: "150",
                            height: "45"
                        }, null, -1)
                    ]), 8, _hoisted_2)),
                _createElementVNode("div", _hoisted_3, [
                    (!__props.hideMobileMenu)
                        ? (_openBlock(), _createBlock(MenuButton, {
                            key: 0,
                            "navigation-text": props.t.navigationText
                        }, null, 8, ["navigation-text"]))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(["gh-menu-right", { 'hide-nav-ele': hideNavigationElements.value }])
                }, [
                    _createElementVNode("ul", _hoisted_4, [
                        (props.mtvHeaderEnabled)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                                _createVNode(Profile, {
                                    t: props.t,
                                    "can-access-member-menu": props.canAccessMemberMenu,
                                    "can-access-six-weeks-program-profile": props.canAccessSixWeeksProgramProfile,
                                    "can-consumer-view-dashboard": props.canConsumerViewDashboard,
                                    "can-gym-owner-view-dashboard": props.canGymOwnerViewDashboard,
                                    "can-manage-gym-profile": props.canManageGymProfile,
                                    "can-manage-linked-gym-instructors": props.canManageLinkedGymInstructors,
                                    "current-user-locale": props.currentUserLocale,
                                    "ga-label": props.gaLabel,
                                    "gym-active-link": props.gymActiveLink,
                                    "is-authenticated": props.isAuthenticated,
                                    "is-trained-non-member": props.isTrainedNonMember,
                                    "item-class": props.itemClass,
                                    "link-class": props.linkClass,
                                    "should-show-join-link": props.shouldShowJoinLink,
                                    "user-has-delinquent-membership": props.userHasDelinquentMembership,
                                    "user-has-benefit-member-portal": props.userHasBenefitMemberPortal,
                                    "zin-responsive-link": props.zinResponsiveLink,
                                    "login-url": props.loginUrl,
                                    "sign-up-url": props.signUpUrl,
                                    "is-pre-payment-access-enabled": props.isPrePaymentAccessEnabled
                                }, null, 8, ["t", "can-access-member-menu", "can-access-six-weeks-program-profile", "can-consumer-view-dashboard", "can-gym-owner-view-dashboard", "can-manage-gym-profile", "can-manage-linked-gym-instructors", "current-user-locale", "ga-label", "gym-active-link", "is-authenticated", "is-trained-non-member", "item-class", "link-class", "should-show-join-link", "user-has-delinquent-membership", "user-has-benefit-member-portal", "zin-responsive-link", "login-url", "sign-up-url", "is-pre-payment-access-enabled"])
                            ]))
                            : _createCommentVNode("", true),
                        (props.mtvHeaderEnabled)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                                _renderSlot(_ctx.$slots, "countryflag")
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ], 2),
                _createElementVNode("ul", {
                    class: _normalizeClass(["gh-main-nav", { 'hide-nav-ele': hideNavigationElements.value }])
                }, [
                    (props.isSixWeekTopNavLinkEnabled &&
                        props.isSupportedSixWeekLocale &&
                        !props.isBaiUrl &&
                        !props.isTrainingUrl &&
                        !props.isZumbaAppMenuLaunchEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                            (props.userHasBenefitSixWeekProgram)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    class: "button is-green six-week-program__button",
                                    href: '/' + props.currentUserLocale + '/6week',
                                    title: props.t.sixWeekProgramText,
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Six Week Program",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "navigation_menu",
                                    "data-ga4-action": "Six Week Program",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.sixWeekProgramText), 9, _hoisted_8))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "button is-green six-week-program__button",
                                    href: props.sixWeekSplashPageUrl,
                                    target: "_blank",
                                    rel: "noopener",
                                    title: props.t.splashPageMenuItemText,
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Six Week Program",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "navigation_menu",
                                    "data-ga4-action": "Six Week Program",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.splashPageMenuItemText), 9, _hoisted_9))
                        ]))
                        : _createCommentVNode("", true),
                    (props.isZumbaAppMenuLaunchEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                            _createElementVNode("a", {
                                class: "gh-main-nav-link",
                                href: '/' + props.localizationUser + '/party',
                                title: props.t.localClassesTitleCaseText,
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Classes",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.localClassesTitleCaseText), 9, _hoisted_11),
                            _createElementVNode("ul", _hoisted_12, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/class_search?searchType=in_person',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find an In Person Class",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_Find an In Person Class",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.inPersonClassesTitleCaseText), 9, _hoisted_13)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/class_search?searchType=virtual',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find a Zoom Class",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_Find a Zoom Class",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.zoomClassesTitleCaseText), 9, _hoisted_14)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/class_search?searchType=vod',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find an VOD Class",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_Find a VOD Class",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.VODClassesTitleCaseText), 9, _hoisted_15)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/pages/class',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find a Class",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_Find a Class",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.classTypesText), 9, _hoisted_16)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/event/nearMe',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find an Event",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_Find an Event",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.eventsTitleCaseText), 9, _hoisted_17)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/instructor',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find an Instructor",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_Find an Instructor",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.findInstructorsText), 9, _hoisted_18)
                                ]),
                                (props.isJobBoardFormEnabled)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/clubs#job-form',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Classes_Hire an Instructor",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Classes_Hire an Instructor",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.postAJobText), 9, _hoisted_20)
                                    ]))
                                    : _createCommentVNode("", true)
                            ])
                        ]))
                        : (_openBlock(), _createElementBlock("li", _hoisted_21, [
                            _createElementVNode("a", {
                                class: "gh-main-nav-link",
                                href: '/' + props.localizationUser + '/party',
                                title: props.t.classesTitleCaseText,
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Classes",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.classesTitleCaseText), 9, _hoisted_22),
                            _createElementVNode("ul", _hoisted_23, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/pages/class',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_About our Classes",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_About our Classes",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.aboutOurClassesText), 9, _hoisted_24)
                                ]),
                                (props.classLocatorEnabled)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_25, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/class_search' + (props.isCirclUrl ? '?class-type=circl-mobility' : ''),
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Classes_Find a Class",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Classes_Find a Class",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.findAClassText), 9, _hoisted_26)
                                    ]))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/party/nearMe?date=' + _unref(todayISO)(),
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Classes_Find a Class",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "Classes_Find a Class",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.findAnInPersonClassText), 9, _hoisted_27)
                                        ]),
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: props.onlineClassBaseUrl + '/?locale=' + props.currentUserLocale.replace('-', '_'),
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Classes_Find a Virtual Class",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "Classes_Find a Virtual Class",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.findAVirtualClassText), 9, _hoisted_28)
                                        ])
                                    ], 64)),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/event/nearMe',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find an Event",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_Find an Event",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.findAnEventText), 9, _hoisted_29)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/instructor',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Classes_Find an Instructor",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Classes_Find an Instructor",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.findAnInstructorText), 9, _hoisted_30)
                                ]),
                                _createElementVNode("li", null, [
                                    (props.isBotBaiCoronavirusEnabled && !props.userHasBenefitSpecialtyTrainingAccess && props.isOnlineCountryUs)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            href: '/' + props.currentUserLocale + '/online-program',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Classes_Become an Instructor",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Classes_Become an Instructor",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.becomeAnInstructorText), 9, _hoisted_31))
                                        : (_openBlock(), _createElementBlock("a", {
                                            key: 1,
                                            href: '/' + props.currentUserLocale + '/become-a-zumba-instructor',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Classes_Become an Instructor",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Classes_Become an Instructor",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.becomeAnInstructorText), 9, _hoisted_32))
                                ]),
                                _createElementVNode("li", null, [
                                    (props.isSixWeekLaunchEnabled && props.currentUserLocale == 'en-US' && !props.userHasBenefitSixWeekProgram)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            href: props.sixWeekSplashPageUrl,
                                            target: "_blank",
                                            rel: "noopener",
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Six Weeks Program Splash Page",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Six Weeks Program Splash Page",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.splashPageMenuItemText), 9, _hoisted_33))
                                        : (props.isSixWeekLaunchEnabled && props.currentUserLocale == 'en-US' && props.userHasBenefitSixWeekProgram)
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: 1,
                                                href: '/' + props.currentUserLocale + '/6week',
                                                target: "_blank",
                                                rel: "noopener",
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Six Weeks Program Splash Page",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "Six Weeks Program Splash Page",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.splashPageMenuItemText), 9, _hoisted_34))
                                            : _createCommentVNode("", true)
                                ])
                            ])
                        ])),
                    (props.isZumbaAppMenuLaunchEnabled && !props.isZumbaAppNoMenuButtonEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_35, [
                            _createElementVNode("a", {
                                class: "gh-main-nav-link",
                                href: props.appLandingUrl,
                                title: props.t.zumbaAppTitleCaseText,
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Zumba App",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Zumba App",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.zumbaAppTitleCaseText), 9, _hoisted_36),
                            _createElementVNode("ul", _hoisted_37, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: getAppUrl.value,
                                        target: "_blank",
                                        rel: "noopener",
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Zumba_App get_app",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Zumba_App get_app",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.getTheAppTitleCaseText), 9, _hoisted_38)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: props.virtualPlusSubscriptionUrl,
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Zumba_App subscription_url",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Zumba_App subscription_url",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.virtualPlusClassesTitleCaseText), 9, _hoisted_39)
                                ])
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (props.isZumbaAppNoMenuButtonEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_40, [
                            _createElementVNode("a", {
                                class: "gh-main-nav-link",
                                href: props.isZin ? '/zumba-app-zin' : '/app-subscription',
                                title: props.t.zumbaAppTitleCaseText,
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Zumba App",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Zumba App",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.zumbaAppTitleCaseText), 9, _hoisted_41)
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("li", _hoisted_42, [
                        (props.isBotBaiCoronavirusEnabled && !props.userHasBenefitSpecialtyTrainingAccess && props.isOnlineCountryUs)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: "gh-main-nav-link",
                                href: '/' + props.currentUserLocale + '/online-program',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Instructor Trainings",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructor Trainings",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.instructorTrainingsDetailsText), 9, _hoisted_43))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                class: "gh-main-nav-link",
                                href: `/${props.currentUserLocale}/${props.userHasBenefitSpecialtyTrainingAccess ? 'training' : 'become-a-zumba-instructor'}`,
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Instructor Trainings",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructor Trainings",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.instructorTrainingsDetailsText), 9, _hoisted_44)),
                        _createElementVNode("ul", _hoisted_45, [
                            (props.isBotBaiCoronavirusEnabled && !props.userHasBenefitSpecialtyTrainingAccess && props.isOnlineCountryUs)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("li", null, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/online-program',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Instructor Trainings_Become an Instructor",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Instructor Trainings_Become an Instructor",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.becomeAnInstructorText), 9, _hoisted_46)
                                    ]),
                                    _createElementVNode("li", null, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/training/nearby?type%5B0%5D=zumba_basic_1&type%5B1%5D=jump_start_gold&type%5B2%5D=jump_start_kids',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Instructor Find A Training",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Instructor Find A Training",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.findATrainingText), 9, _hoisted_47)
                                    ])
                                ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    (!props.userHasBenefitSpecialtyTrainingAccess)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_48, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/become-a-zumba-instructor',
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Instructor Trainings_Become an Instructor",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "Instructor Trainings_Become an Instructor",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.becomeAnInstructorText), 9, _hoisted_49)
                                        ]))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("li", null, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/training/nearby?type%5B0%5D=zumba_basic_1&type%5B1%5D=jump_start_gold&type%5B2%5D=jump_start_kids',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Instructor Find A Training",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Instructor Find A Training",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.findATrainingText), 9, _hoisted_50)
                                    ])
                                ], 64)),
                            _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                    href: '/' + props.currentUserLocale + '/trainings',
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Instructor Trainings_View All Trainings",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "navigation_menu",
                                    "data-ga4-action": "Instructor Trainings_View All Trainings",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.isMaximizeb1SitenavTrainingtypesEnabled
                                    ? props.t.trainingTypesText
                                    : props.t.viewAllTrainingsText), 9, _hoisted_51)
                            ]),
                            (props.isZjRefactorNavigationEnabled)
                                ? (_openBlock(), _createBlock(Sessions, {
                                    key: 2,
                                    t: props.t,
                                    "current-user-locale": props.currentUserLocale,
                                    "ga-label": props.gaLabel,
                                    "is-authenticated": props.isAuthenticated
                                }, null, 8, ["t", "current-user-locale", "ga-label", "is-authenticated"]))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _createElementVNode("li", _hoisted_52, [
                        _createElementVNode("a", {
                            class: "gh-main-nav-link",
                            href: props.shopUrl,
                            title: props.t.shopText,
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Shop",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "Shop",
                            "data-ga4-details": props.gaLabel,
                            target: "_blank",
                            rel: "noopener"
                        }, _toDisplayString(props.t.shopText), 9, _hoisted_53)
                    ])
                ], 2)
            ], 2));
        };
    }
});
