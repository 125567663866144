import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "data-ga-label"];
const _hoisted_5 = { class: "ocn-list-item" };
const _hoisted_6 = ["href", "data-ga-label"];
const _hoisted_7 = { class: "ocn-list-item" };
const _hoisted_8 = ["data-ga-label"];
const _hoisted_9 = { class: "ocn-list-item" };
const _hoisted_10 = ["href", "data-ga-label"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Support',
    props: {
        active: { type: String, default: '' },
        t: {
            type: Object,
            default: () => ({
                supportText: 'Support',
                faqsText: 'FAQs',
                contactUsText: 'Contact Us',
                returnsText: 'Returns',
                shippingPolicyText: 'Shipping Policy',
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        shippingTerms: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("a", {
                    class: _normalizeClass(["more-below ocn-list-link", { 'js-active-item inactive-item': props.active }]),
                    href: "#",
                    title: props.t.supportText
                }, _toDisplayString(props.t.supportText), 11, _hoisted_2),
                _createElementVNode("ul", {
                    class: "ocn-subnav",
                    style: _normalizeStyle(props.active ? 'display: block;' : '')
                }, [
                    _createElementVNode("li", _hoisted_3, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/faq',
                            class: _normalizeClass([{ 'js-active-item active-item': (props.active == '/faq') }, "ocn-list-link"]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Support_FAQs",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(props.t.faqsText), 11, _hoisted_4)
                    ]),
                    _createElementVNode("li", _hoisted_5, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/contact',
                            class: _normalizeClass([{ 'js-active-item active-item': (props.active == '/contact') }, "ocn-list-link"]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Support_Contact Us",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(props.t.contactUsText), 11, _hoisted_6)
                    ]),
                    _createElementVNode("li", _hoisted_7, [
                        _createElementVNode("a", {
                            href: "https://zumbaapparel.happyreturns.com/",
                            class: "ocn-list-link",
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Support_Return Policy",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(props.t.returnsText), 9, _hoisted_8)
                    ]),
                    _createElementVNode("li", _hoisted_9, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + props.shippingTerms,
                            class: _normalizeClass([{ 'js-active-item active-item': (props.active == '/support/policy/shipping') }, "ocn-list-link"]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Support_Shipping Policy",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(props.t.shippingPolicyText), 11, _hoisted_10)
                    ])
                ], 4)
            ]));
        };
    }
});
