import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, unref as _unref, Fragment as _Fragment, renderSlot as _renderSlot, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_2 = ["href", "data-ga-label"];
const _hoisted_3 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_4 = ["href", "data-ga-label"];
const _hoisted_5 = ["href", "data-ga-label"];
const _hoisted_6 = ["href", "data-ga-label"];
const _hoisted_7 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_8 = ["href", "data-ga-label"];
const _hoisted_9 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_10 = ["title"];
const _hoisted_11 = { class: "ocn-list-item" };
const _hoisted_12 = ["href", "title"];
const _hoisted_13 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_14 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_15 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_16 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_17 = ["href", "data-ga-label"];
const _hoisted_18 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_19 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_20 = ["href"];
const _hoisted_21 = { class: "new-feature-item" };
const _hoisted_22 = { class: "new-feature-tag-wrap" };
const _hoisted_23 = { class: "ocn-list-item" };
const _hoisted_24 = ["href", "data-ga-label"];
const _hoisted_25 = { class: "ocn-list-item" };
const _hoisted_26 = ["href", "data-ga-label"];
const _hoisted_27 = { class: "ocn-list-item" };
const _hoisted_28 = ["href", "data-ga-label"];
const _hoisted_29 = { class: "ocn-list-item" };
const _hoisted_30 = ["href", "data-ga-label"];
const _hoisted_31 = { class: "ocn-list-item" };
const _hoisted_32 = ["href", "data-ga-label"];
const _hoisted_33 = {
    key: 3,
    class: "ocn-list-item",
    "mtv-strong-hide": ""
};
const _hoisted_34 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_35 = ["href", "data-ga-label"];
const _hoisted_36 = {
    key: 4,
    class: "ocn-list-item",
    "mtv-zumba-hide": ""
};
const _hoisted_37 = ["href", "data-ga-label"];
const _hoisted_38 = {
    key: 5,
    class: "ocn-list-item"
};
const _hoisted_39 = ["href", "data-ga-label"];
const _hoisted_40 = {
    key: 6,
    class: "ocn-list-item"
};
const _hoisted_41 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_42 = ["href", "data-ga-label"];
const _hoisted_43 = {
    key: 7,
    class: "ocn-list-item"
};
const _hoisted_44 = ["href", "data-ga-label"];
const _hoisted_45 = {
    key: 8,
    class: "ocn-list-item"
};
const _hoisted_46 = ["href"];
const _hoisted_47 = { class: "ocn-list-item" };
const _hoisted_48 = ["href", "data-ga-label"];
const _hoisted_49 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_50 = ["href", "data-ga-category", "data-ga-label"];
const _hoisted_51 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_52 = ["href", "data-ga-label"];
const _hoisted_53 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_54 = ["href", "data-ga-label"];
const _hoisted_55 = { class: "ocn-list-item" };
const _hoisted_56 = ["href", "data-ga-label"];
const _hoisted_57 = {
    key: 3,
    class: "ocn-list-item"
};
const _hoisted_58 = ["href", "data-ga-label"];
const _hoisted_59 = {
    key: 4,
    class: "ocn-list-item"
};
const _hoisted_60 = ["href", "data-ga-label"];
const _hoisted_61 = {
    key: 5,
    class: "ocn-list-item"
};
const _hoisted_62 = ["href", "data-ga-label"];
const _hoisted_63 = {
    key: 9,
    class: "ocn-list-item"
};
const _hoisted_64 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_65 = ["href"];
const _hoisted_66 = { class: "ocn-list-item" };
const _hoisted_67 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_68 = ["href", "data-ga-label"];
const _hoisted_69 = { class: "ocn-list-item" };
const _hoisted_70 = ["href", "data-ga-label"];
const _hoisted_71 = {
    class: "ocn-list-item",
    "mtv-strong-hide": ""
};
const _hoisted_72 = ["href", "data-ga-label"];
const _hoisted_73 = {
    key: 1,
    class: "ocn-list-item",
    "mtv-strong-hide": ""
};
const _hoisted_74 = ["href", "data-ga-label"];
const _hoisted_75 = {
    key: 10,
    class: "ocn-list-item"
};
const _hoisted_76 = { class: "ocn-list-item" };
const _hoisted_77 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_78 = ["href", "data-ga-label"];
const _hoisted_79 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_80 = ["href", "data-ga-action"];
const _hoisted_81 = {
    key: 0,
    class: "new-feature-item"
};
const _hoisted_82 = {
    key: 1,
    class: "new-feature-tag-wrap"
};
const _hoisted_83 = { key: 2 };
const _hoisted_84 = {
    key: 1,
    class: "ocn-list-item",
    "mtv-strong-hide": ""
};
const _hoisted_85 = { class: "ocn-list-item" };
const _hoisted_86 = ["href", "data-ga-label"];
const _hoisted_87 = ["href", "data-ga-label"];
const _hoisted_88 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_89 = ["data-ga-label"];
const _hoisted_90 = {
    key: 3,
    class: "ocn-list-item"
};
const _hoisted_91 = ["data-ga-label"];
const _hoisted_92 = {
    key: 4,
    class: "ocn-list-item"
};
const _hoisted_93 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_94 = ["href"];
const _hoisted_95 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_96 = { class: "ocn-list-item__no-access ocn-list-link" };
const _hoisted_97 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_98 = ["href", "data-ga-category"];
const _hoisted_99 = {
    key: 6,
    class: "ocn-list-item"
};
const _hoisted_100 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_101 = {
    key: 7,
    class: "ocn-list-item",
    "mtv-strong-hide": ""
};
const _hoisted_102 = ["href"];
const _hoisted_103 = {
    key: 11,
    class: "ocn-list-item"
};
const _hoisted_104 = { class: "ocn-list-item" };
const _hoisted_105 = ["href"];
const _hoisted_106 = { class: "ocn-list-item" };
const _hoisted_107 = ["innerHTML"];
const _hoisted_108 = { key: 1 };
const _hoisted_109 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_110 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_111 = { class: "ocn-list-item" };
const _hoisted_112 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_113 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_114 = ["href", "data-ga-label"];
const _hoisted_115 = {
    key: 12,
    class: "ocn-list-item"
};
const _hoisted_116 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_117 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_118 = { key: 1 };
const _hoisted_119 = ["href", "data-ga-label"];
const _hoisted_120 = ["href", "data-ga-label"];
const _hoisted_121 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_122 = ["href", "data-ga-label"];
const _hoisted_123 = ["href", "data-ga-label"];
const _hoisted_124 = ["href"];
const _hoisted_125 = { class: "ocn-list-item" };
const _hoisted_126 = ["href", "data-ga-label"];
const _hoisted_127 = { class: "ocn-list-item" };
const _hoisted_128 = ["href", "data-ga-label"];
import { track } from '@ts/Util/analytics';
import { computed } from 'vue';
import OnHold from './OnHold.vue';
import Gym from './Gym.vue';
import Shop from './Shop.vue';
import Classes from './Classes.vue';
import ZumbaApp from './ZumbaApp.vue';
import ZumbaAppButton from './ZumbaAppButton.vue';
import About from './About.vue';
import Instructors from './Instructors.vue';
import Sessions from './Sessions.vue';
import Tag from '@components/Tags/Tag.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UserMenu',
    props: {
        active: { type: Object, default: () => { } },
        t: {
            type: Object,
            default: () => ({
                aboutInstructorTrainingsText: 'Instructor Trainings',
                aboutOurClassesText: 'About our Classes',
                aboutText: 'About',
                aboutZumbaText: 'About Zumba',
                accountOnHoldText: 'Account on Hold',
                accountSummaryText: 'Account Summary',
                afaaGfiForZinText: 'AFAA-GFI For ZIN™',
                becomeAnInstructorText: 'Become an Instructor',
                beginnerVideosText: 'Beginner Videos',
                buySixWeekProgramText: 'Buy 6 Week Program',
                careersText: 'Careers',
                choreographyNotesText: 'Choreography Notes',
                classBuzzAppText: 'ClassBuzz™ App',
                classesText: 'Classes',
                circlNewTagText: 'New',
                communityText: 'Community',
                discountsText: 'Discounts',
                eLearningText: 'E-Learning',
                earningsSupportText: 'Earnings + Support',
                findAClassText: 'Find a Class',
                findATrainingText: 'Find a Training',
                findAVirtualClassText: 'Find a Virtual Class',
                findAnEventText: 'Find an Event',
                findAnInPersonClassText: 'Find An In-Person Class',
                findAnInstructorText: 'Find An Instructor',
                forumText: 'Forum',
                gymAuditionKitText: 'Gym Audition Kit',
                gymsProfileText: 'Gyms Profile',
                hostATrainingText: 'Host a Training',
                instructorAcademiesText: 'Instructor Academies',
                instructorPageText: 'Instructor Page',
                instructorTrainingStatusText: 'Instructor Training Status',
                instructorTrainingsText: 'Instructor Trainings',
                instructorsText: 'Instructors',
                jamSessionsText: 'ZIN&trade; Jam Sessions',
                joinZinText: 'Join ZIN™',
                manageInstructorsText: 'Manage Instructors',
                manageMyAccountText: 'Manage My Account',
                manageMyLocationsText: 'Manage Locations',
                manageZjSessionsText: 'Manage ZJ Sessions',
                marketingMaterialsText: 'Marketing Materials',
                mentorProgramText: 'Mentor Program',
                myAccountText: 'My Account',
                myAnnouncementsText: 'My Announcements',
                myDashboardText: 'My Dashboard',
                myEventsText: 'My Events',
                myTrainingText: 'My Training',
                myTrainingsProgramText: 'My Trainings & Program',
                myTrainingsText: 'My Trainings',
                zinHomeText: 'Zin&trade; Home',
                myZjSessionsText: 'My ZJ Sessions',
                onHoldEndStatusText: 'Your account is currently on hold',
                shopText: 'Shop Clothing',
                sixWeekMenuItemText: '6 Week Program',
                sixWeekProgramText: '6 Week Program',
                sixWeekSplashPageMenuItemText: '6 Week Program',
                stepChallengeText: 'Step Challenge',
                teachingPromotionText: 'Teaching + Promotion',
                tippingText: 'Tipping',
                trainingAttendanceText: 'Training Attendance',
                trainingLocationManagementText: 'Training Location Management',
                trainingMaterialRequestText: 'Training Material Request',
                trainingRegistrationText: 'Training Registration',
                trainingRequestFormText: 'Training Request Form',
                trainingTypeText: 'Training Types',
                trainingsEducationText: 'Trainings + Education',
                updateOnHoldDateText: 'Update on Hold Date',
                updatePaymentText: 'Update Payment',
                userMenuMarketingMaterialsText: 'Marketing Materials',
                viewAllTrainingText: 'View All Training',
                zcomVolunteersText: 'ZCOM Volunteers',
                zesDocsText: 'ZES Docs',
                zesLinksText: 'ZES Links',
                zinNowText: 'ZIN&trade; Now',
                zinPlayAppText: 'ZIN™ Play App',
                zinTradeJamsText: 'ZIN&trade; Jams',
                zumbaBlogText: 'Zumba® Blog',
                zumbaConventionText: 'Zumba Convention',
                zumbaForGymsText: 'Zumba® for Gyms',
                zumbaInstructorLicensingProgramText: 'Zumba Instructor Licensing Program',
                zumbaRegWearInsiderText: 'Zumba&reg; Wear Insider',
                referFriendText: 'Refer A Friend',
                classManagement: 'Class Management',
                newFeature: 'New',
                myClasses: 'My Classes',
                classPacks: 'Class Packs',
                students: 'Students',
                settings: 'Settings',
                localClassesTitleCaseText: 'Local Classes',
                inPersonClassesTitleCaseText: 'In Person',
                zoomClassesTitleCaseText: 'Zoom',
                eventsTitleCaseText: 'Events',
                getTheAppTitleCaseText: 'Get the App',
                virtualClassesTitleCaseText: 'Virtual+ Classes',
                zumbaAppTitleCaseText: 'Zumba App',
                findInstructorsText: 'Find Instructors',
                noomCoachingText: 'Noom Coaching',
                statusCardText: 'ZIN Status Card',
            })
        },
        botPublicZesLinkEnabled: { type: String, default: '' },
        canAccessDcd: { type: String, default: '' },
        canAccessEducationSpecialistDocuments: { type: String, default: '' },
        canAccessEducationSpecialistMenu: { type: String, default: '' },
        canAccessMemberMenu: { type: String, default: '' },
        canAccessSixWeeksProgramProfile: { type: String, default: '' },
        canViewStudentDashboard: { type: Boolean, default: false },
        canConsumerViewDashboard: { type: String, default: '' },
        canGymOwnerViewDashboard: { type: String, default: '' },
        canManageGymProfile: { type: String, default: '' },
        canManageJamSessions: { type: String, default: '' },
        canManageLinkedGymInstructors: { type: String, default: '' },
        canManageTrainingLocations: { type: String, default: '' },
        canRequestTrainingMaterials: { type: String, default: '' },
        canRequestTrainings: { type: String, default: '' },
        currentUserLocale: { type: String, default: '' },
        dashboardMenuItemsEnabled: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        hasPendingFirstTraining: { type: String, default: '' },
        hasPreMembershipLinksOnly: { type: String, default: '' },
        isAfaaDashboardEnabled: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        isBotBaiCoronavirusEnabled: { type: String, default: '' },
        isClassBuzzSplashEnabled: { type: String, default: '' },
        isCookieCountryUs: { type: String, default: '' },
        isDgfPublicEnabled: { type: String, default: '' },
        isGuestUser: { type: String, default: '' },
        isMaximizeb1SitenavTrainingtypesEnabled: { type: String, default: '' },
        isMembershipCirclInstructorNavigationEnabled: { type: String, default: '' },
        isMembershipCirclInstructorNavigationNewEnabled: { type: String, default: '' },
        isMembershipTypeZumba: { type: String, default: '' },
        isMenuLinksEnabled: { type: String, default: '' },
        isOnHold: { type: String, default: '' },
        isOnlineCountryUs: { type: String, default: '' },
        isPoseSideMenuEnabled: { type: String, default: '' },
        isProduction: { type: String, default: '' },
        isSixWeekLaunchEnabled: { type: String, default: '' },
        isSixWeekHub: { type: Boolean, default: false },
        isSupportedSixWeekLocale: { type: String, default: '' },
        isTippingLandingLinkLeftMenuEnabled: { type: String, default: '' },
        isZinHomeAcademyLinksEnabled: { type: String, default: '' },
        isNoomCertificationEnabled: { type: String, default: '' },
        isBenefitMembershipStatusCardEnabled: { type: String, default: '' },
        manageClassesAction: { type: String, default: '' },
        manageClassesActive: { type: String, default: '' },
        memberProfileEnabled: { type: String, default: '' },
        membershipTypeSlug: { type: String, default: '' },
        navigationShopUrl: { type: String, default: '' },
        onHoldEnd: { type: String, default: '' },
        onlineClassBaseUrl: { type: String, default: '' },
        shouldShowJoinLink: { type: String, default: '' },
        userHasDelinquentMembership: { type: String, default: '' },
        isPrePaymentAccessEnabled: { type: String, default: '' },
        sixWeekSplashPageUrl: { type: String, default: '' },
        userHasBenefitCreateOnlineClass: { type: String, default: '' },
        userHasBenefitInstructorProfile: { type: String, default: '' },
        userHasBenefitManageZinEvents: { type: String, default: '' },
        userHasBenefitManageZjSession: { type: String, default: '' },
        userHasBenefitMemberELearning: { type: String, default: '' },
        userHasBenefitPoseChallengeAccess: { type: String, default: '' },
        userHasBenefitSixWeekProgram: { type: String, default: '' },
        userHasBenefitSpecialtyTrainingAccess: { type: String, default: '' },
        userHasBenefitStrongInstructorAccess: { type: String, default: '' },
        userHasBenefitUseZinMentorProgram: { type: String, default: '' },
        userHasBenefitZinDiscounts: { type: String, default: '' },
        userHasBenefitZinJammerSessions: { type: String, default: '' },
        userHasBenefitZumbaForumAccess: { type: String, default: '' },
        userHasBenefitMemberPortal: { type: String, default: '' },
        userHasNoomCertificationAccess: { type: String, default: '' },
        userHasNoomBenefit: { type: String, default: '' },
        userHasBenefitMembershipStatusCard: { type: String, default: '' },
        zinProfileAccountLink: { type: String, default: '' },
        classManagementEnabled: { type: Boolean, default: false },
        classLocatorEnabled: { type: String, default: '' },
        isCirclUrl: { type: String, default: '' },
        restrictedReferFriend: { type: Boolean, default: false },
        restrictedInstructorTraining: { type: Boolean, default: false },
        isZumbaAppMenuLaunchEnabled: { type: String, default: '' },
        isZumbaAppNoMenuButtonEnabled: { type: Boolean, default: false },
        isZin: { type: Boolean, default: false },
        virtualPlusSubscriptionUrl: { type: String, default: '' },
        appStoreUrl: { type: String, default: '' },
        playStoreUrl: { type: String, default: '' },
        downloadAppUrl: { type: String, default: '' },
        appLandingUrl: { type: String, default: '' },
        newReferralProgram: { type: Boolean, default: false },
        showShareAndEarn: { type: Boolean, default: false },
        isJobBoardFormEnabled: { type: String, default: '' },
        hideJapaneseMenu: { type: Boolean, default: false },
        isGymPostAJobEnabled: { type: Boolean, default: false }
    },
    setup(__props) {
        const props = __props;
        const showNoom = computed(() => props.isNoomCertificationEnabled && props.userHasNoomCertificationAccess && !props.hideJapaneseMenu);
        const openTools = computed(() => {
            const currentUrl = window.location.pathname;
            return props.classManagementEnabled && (currentUrl.startsWith('/' + props.currentUserLocale + '/dashboard'));
        });
        const getAppUrl = computed(() => {
            let url = props.downloadAppUrl;
            const isIos = navigator.userAgent.match(/iP(ad|hone|od)/i) !== null;
            const isAndroid = navigator.userAgent.match(/Android/i) !== null;
            if (isAndroid) {
                url = props.playStoreUrl;
            }
            if (isIos) {
                url = props.appStoreUrl;
            }
            return url;
        });
        const openClassManagement = computed(() => {
            const currentUrl = window.location.pathname;
            return props.classManagementEnabled && (currentUrl.startsWith('/' + props.currentUserLocale + '/member/class_management') ||
                currentUrl.startsWith('/' + props.currentUserLocale + '/member/instructor_page/') ||
                currentUrl.startsWith('/' + props.currentUserLocale + '/member/packages'));
        });
        const openNestedSubmenu = computed(() => {
            return props.active?.['Teach'] || props.active?.['Learn'] || props.active?.['Earn'] || props.active?.['Connect'];
        });
        const isKeyEmpty = (key) => Object.keys(key ?? {}).length == 0;
        const studentDashboardText = computed(() => props.userHasBenefitMemberPortal ? props.t.activityAsAStudentText : props.t.activityText);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (props.isAuthenticated)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (!props.isGuestUser)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                                (!props.canAccessMemberMenu && props.canConsumerViewDashboard)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        href: '/' + props.currentUserLocale + '/training/history',
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/training/history'] }]),
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Trainings",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.dashboardMenuItemsEnabled && props.canAccessMemberMenu
                                        ? props.t.myTrainingsProgramText
                                        : props.t.myTrainingsText), 11, _hoisted_2))
                                    : _createCommentVNode("", true),
                                (props.canAccessMemberMenu && !props.hasPreMembershipLinksOnly)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/dashboard'] }]),
                                        href: '/' + props.currentUserLocale + '/dashboard',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "ZIN Dashboard",
                                        "data-ga-label": props.gaLabel,
                                        innerHTML: props.t.zinHomeText
                                    }, null, 10, _hoisted_3))
                                    : (props.userHasDelinquentMembership)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 2,
                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/student_dashboard'] }]),
                                            href: '/' + props.currentUserLocale + '/student_dashboard',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "My Dashboard",
                                            "data-ga-label": props.gaLabel
                                        }, _toDisplayString(props.t.myDashboardText), 11, _hoisted_4))
                                        : (((props.canManageGymProfile && props.canGymOwnerViewDashboard) || (props.canConsumerViewDashboard && props.canAccessMemberMenu)))
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: 3,
                                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/dashboard'] }]),
                                                href: '/' + props.currentUserLocale + '/dashboard',
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "My Dashboard",
                                                "data-ga-label": props.gaLabel
                                            }, _toDisplayString(props.t.myDashboardText), 11, _hoisted_5))
                                            : _createCommentVNode("", true),
                                (props.hasPendingFirstTraining)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 4,
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/dashboard'] }]),
                                        href: '/' + props.currentUserLocale + '/training/history',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Training History",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.t.myTrainingText), 11, _hoisted_6))
                                    : _createCommentVNode("", true),
                                (props.shouldShowJoinLink)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 5,
                                        class: "ocn-list-link",
                                        href: '/' + props.currentUserLocale + '/dashboard',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Join ZIN",
                                        "data-ga-label": props.gaLabel,
                                        innerHTML: props.t?.joinZinText
                                    }, null, 8, _hoisted_7))
                                    : (props.userHasDelinquentMembership)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 6,
                                            class: "ocn-list-link",
                                            href: '/' + props.currentUserLocale + (props.isPrePaymentAccessEnabled ? '/membership/pay' : '/dashboard'),
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Update Payment",
                                            "data-ga-label": props.gaLabel
                                        }, _toDisplayString(props.t.updatePaymentText), 9, _hoisted_8))
                                        : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true),
                        (!props.userHasDelinquentMembership && props.canAccessMemberMenu)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                                _createElementVNode("a", {
                                    class: _normalizeClass(["more-below ocn-list-link", {
                                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Tools']) || openTools.value || openClassManagement.value || openNestedSubmenu.value,
                                        }]),
                                    href: "#",
                                    title: props.t.toolText
                                }, _toDisplayString(props.t.toolText), 11, _hoisted_10),
                                _createElementVNode("ul", {
                                    class: "ocn-subnav",
                                    style: _normalizeStyle((!isKeyEmpty(props.active?.['Tools']) || openTools.value || openClassManagement.value || openNestedSubmenu.value) ? 'display: block;' : '')
                                }, [
                                    _createElementVNode("li", _hoisted_11, [
                                        ((props.isBenefitMembershipStatusCardEnabled && props.userHasBenefitMembershipStatusCard))
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: 0,
                                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/membership/status_card' + props.membershipTypeSlug.toLowerCase()] }]),
                                                href: '/' + props.currentUserLocale + '/membership/status_card/' + props.membershipTypeSlug.toLowerCase(),
                                                title: props.t.statusCardText
                                            }, _toDisplayString(props.t.statusCardText), 11, _hoisted_12))
                                            : _createCommentVNode("", true)
                                    ]),
                                    (props.canAccessDcd)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                                            _createElementVNode("a", {
                                                id: "hamburger-link-learn-zin-media",
                                                href: '/' + props.currentUserLocale + '/now',
                                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/now'] }]),
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Now",
                                                "data-ga-label": props.gaLabel,
                                                innerHTML: props.t.zinNowText
                                            }, null, 10, _hoisted_14)
                                        ]))
                                        : _createCommentVNode("", true),
                                    _createVNode(OnHold, {
                                        t: props.t,
                                        "current-user-locale": props.currentUserLocale,
                                        "is-on-hold": props.isOnHold,
                                        "on-hold-end": props.onHoldEnd
                                    }, null, 8, ["t", "current-user-locale", "is-on-hold", "on-hold-end"]),
                                    (props.canAccessMemberMenu && !props.classManagementEnabled)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
                                            (props.hasPreMembershipLinksOnly)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(props.t.manageClassesText), 1))
                                                : (_openBlock(), _createElementBlock("a", {
                                                    key: 1,
                                                    href: props.manageClassesAction,
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.manageClassesActive }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "Tools_My Classes",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.manageClassesText), 11, _hoisted_17))
                                        ]))
                                        : _createCommentVNode("", true),
                                    (props.canAccessMemberMenu && props.classManagementEnabled)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_18, [
                                            (props.hasPreMembershipLinksOnly)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(props.t.manageClassesText), 1))
                                                : _createCommentVNode("", true),
                                            (!props.hasPreMembershipLinksOnly)
                                                ? (_openBlock(), _createElementBlock("a", {
                                                    key: 1,
                                                    href: '/' + props.currentUserLocale + '/member/class_management',
                                                    class: _normalizeClass(["ocn-list-link more-below with-new-feature-badge", {
                                                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement.value,
                                                            'pre-selected-active': !isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement.value
                                                        }])
                                                }, [
                                                    _createElementVNode("span", _hoisted_21, _toDisplayString(props.t.classManagement), 1),
                                                    _createElementVNode("span", _hoisted_22, [
                                                        _createVNode(Tag, {
                                                            variety: "small",
                                                            "show-cancel": false,
                                                            class: "new-feature-tag"
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(props.t.newFeature), 1)
                                                            ]),
                                                            _: 1
                                                        })
                                                    ])
                                                ], 10, _hoisted_20))
                                                : _createCommentVNode("", true),
                                            (!props.hasPreMembershipLinksOnly)
                                                ? (_openBlock(), _createElementBlock("ul", {
                                                    key: 2,
                                                    class: "ocn-subnav subnav-nested",
                                                    style: _normalizeStyle(!isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement.value ? 'display: block;' : '')
                                                }, [
                                                    _createElementVNode("li", _hoisted_23, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/member/class_management',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management'] }]),
                                                            "data-ga-category": "Class Management",
                                                            "data-ga-action": "My Classes",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.myClasses), 11, _hoisted_24)
                                                    ]),
                                                    _createElementVNode("li", _hoisted_25, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/member/instructor_page/view',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/instructor_page/view'] }]),
                                                            "data-ga-category": "Class Management",
                                                            "data-ga-action": "Instructor Page",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.instructorPageText), 11, _hoisted_26)
                                                    ]),
                                                    _createElementVNode("li", _hoisted_27, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/member/packages',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/packages'] }]),
                                                            "data-ga-category": "Class Management",
                                                            "data-ga-action": "Class Packs",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.classPacks), 11, _hoisted_28)
                                                    ]),
                                                    _createElementVNode("li", _hoisted_29, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/member/class_management/students',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management/students'] }]),
                                                            "data-ga-category": "Class Management",
                                                            "data-ga-action": "Students",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.students), 11, _hoisted_30)
                                                    ]),
                                                    _createElementVNode("li", _hoisted_31, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/member/class_management/settings',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management/settings'] }]),
                                                            "data-ga-category": "Class Management",
                                                            "data-ga-action": "Settings",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.settings), 11, _hoisted_32)
                                                    ])
                                                ], 4))
                                                : _createCommentVNode("", true)
                                        ]))
                                        : _createCommentVNode("", true),
                                    ((props.canAccessMemberMenu && props.memberProfileEnabled && props.userHasBenefitInstructorProfile) && !openClassManagement.value)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_33, [
                                            (props.hasPreMembershipLinksOnly)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(props.t.instructorPageText), 1))
                                                : (_openBlock(), _createElementBlock("a", {
                                                    key: 1,
                                                    href: '/' + props.currentUserLocale + '/member/instructor_page/view',
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Tools']?.['/member/website_forward'] }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "Instructor_Page",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.instructorPageText), 11, _hoisted_35))
                                        ]))
                                        : _createCommentVNode("", true),
                                    ((props.canAccessMemberMenu && props.memberProfileEnabled && props.userHasBenefitInstructorProfile))
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_36, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/member/profile',
                                                class: "ocn-list-link",
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Instructor_Page",
                                                "data-ga-label": props.gaLabel
                                            }, _toDisplayString(props.t.instructorPageText), 9, _hoisted_37)
                                        ]))
                                        : _createCommentVNode("", true),
                                    ((props.canAccessMemberMenu && props.canManageJamSessions))
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_38, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/zj/session/my',
                                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Tools']?.['/zj/session/my'] }]),
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Tools_My ZJ Sessions",
                                                "data-ga-label": props.gaLabel
                                            }, _toDisplayString(props.t.myZjSessionsText), 11, _hoisted_39)
                                        ]))
                                        : _createCommentVNode("", true),
                                    ((props.canAccessMemberMenu && props.userHasBenefitManageZinEvents))
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_40, [
                                            (props.hasPreMembershipLinksOnly)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString(props.t.myEventsText), 1))
                                                : (_openBlock(), _createElementBlock("a", {
                                                    key: 1,
                                                    href: '/' + props.currentUserLocale + '/member/event/manage',
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Tools']?.['/member/event/manage'] }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "Tools_My Events",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.myEventsText), 11, _hoisted_42))
                                        ]))
                                        : _createCommentVNode("", true),
                                    (!props.isGuestUser)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_43, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/training/history',
                                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/training/history'] }]),
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Trainings",
                                                "data-ga-label": props.gaLabel
                                            }, _toDisplayString(props.dashboardMenuItemsEnabled
                                                ? props.t.myTrainingsProgramText
                                                : props.t.myTrainingsText), 11, _hoisted_44)
                                        ]))
                                        : _createCommentVNode("", true),
                                    (props.canAccessMemberMenu && props.canAccessEducationSpecialistMenu)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_45, [
                                            _createElementVNode("a", {
                                                class: _normalizeClass(["more-below ocn-list-link", {
                                                        'js-active-item inactive-item': !isKeyEmpty(props.active?.['ZES Support']),
                                                    }]),
                                                href: '/' + props.currentUserLocale + '/support'
                                            }, _toDisplayString(props.t.zesLinksText), 11, _hoisted_46),
                                            _createElementVNode("ul", {
                                                class: "ocn-subnav subnav-nested",
                                                style: _normalizeStyle(!isKeyEmpty(props.active?.['ZES Support']) ? 'display: block;' : '')
                                            }, [
                                                _createElementVNode("li", _hoisted_47, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/es/training_status',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_status']) }]),
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "ZES Links_Instructor Training Status",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.instructorTrainingStatusText), 11, _hoisted_48)
                                                ]),
                                                (props.isDgfPublicEnabled)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_49, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/es/training_status/attendance',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_status/attendance']) }]),
                                                            "data-ga-category": props.membershipTypeSlug + ' Home',
                                                            "data-ga-action": "Training Attendance",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.trainingAttendanceText), 11, _hoisted_50)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                (props.canManageTrainingLocations)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_51, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/es/location/training_index',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/location/training_index']) }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "ZES Links_Training Location Management",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.trainingLocationManagementText), 11, _hoisted_52)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                (props.canRequestTrainings)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_53, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/es/training_request',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_request']) }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "ZES Links_Training Request Form",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.trainingRequestFormText), 11, _hoisted_54)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                _createElementVNode("li", _hoisted_55, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/es/training_registration/add',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_registration/add']) }]),
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "ZES Links_Training Registration",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.trainingRegistrationText), 11, _hoisted_56)
                                                ]),
                                                (props.canAccessEducationSpecialistDocuments)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_57, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/es/zes_document',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/zes_document']) }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "ZES Links_ZES Docs",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.zesDocsText), 11, _hoisted_58)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                (props.canRequestTrainingMaterials)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_59, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/es/training_material',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_material']) }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "ZES Links_Training Material Request",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.trainingMaterialRequestText), 11, _hoisted_60)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                ((props.canAccessEducationSpecialistMenu && props.botPublicZesLinkEnabled))
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_61, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/training/online/trainingTypeSlug:zumba_basic_1',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_material']) }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "ZES Links_Training Material Request",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.zumbaInstructorLicensingProgramText), 11, _hoisted_62)
                                                    ]))
                                                    : _createCommentVNode("", true)
                                            ], 4)
                                        ]))
                                        : _createCommentVNode("", true),
                                    (props.canAccessMemberMenu)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_63, [
                                            _createElementVNode("a", {
                                                class: _normalizeClass(["more-below ocn-list-link", {
                                                        'js-active-item inactive-item': !isKeyEmpty(props.active?.['Teach']),
                                                        'pre-selected-active': !isKeyEmpty(props.active?.['Teach'])
                                                    }]),
                                                href: "#"
                                            }, _toDisplayString(props.t.teachingPromotionText), 3),
                                            _createElementVNode("ul", {
                                                class: "ocn-subnav subnav-nested",
                                                style: _normalizeStyle(!isKeyEmpty(props.active?.['Teach']) ? 'display: block;' : '')
                                            }, [
                                                (props.userHasBenefitManageZjSession)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_64, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/trainer/session/my',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Teach']?.['/trainer/session/my'] }])
                                                        }, _toDisplayString(props.t.manageZjSessionsText), 11, _hoisted_65)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                _createElementVNode("li", _hoisted_66, [
                                                    (props.hasPreMembershipLinksOnly)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_67, _toDisplayString(props.t.userMenuMarketingMaterialsText), 1))
                                                        : (_openBlock(), _createElementBlock("a", {
                                                            key: 1,
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Teach']?.['/marketing_materials'] }]),
                                                            target: "_blank",
                                                            rel: "noopener",
                                                            href: '/' + props.currentUserLocale + '/marketing_materials',
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Teaching Promotion_Marketing Materials",
                                                            "data-ga-label": props.gaLabel,
                                                            onClick: _cache[0] || (_cache[0] = () => _unref(track)('MM: Downloads', {}))
                                                        }, _toDisplayString(props.t.userMenuMarketingMaterialsText), 11, _hoisted_68))
                                                ]),
                                                _createElementVNode("li", _hoisted_69, [
                                                    _createElementVNode("a", {
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Teach']?.['/member/choreography'] }]),
                                                        href: '/' + props.currentUserLocale + '/member/choreography',
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "Teaching Promotion_Choreo Notes",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.choreographyNotesText), 11, _hoisted_70)
                                                ]),
                                                _createElementVNode("li", _hoisted_71, [
                                                    _createElementVNode("a", {
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Teach']?.['/zinplay'] }]),
                                                        href: '/' + props.currentUserLocale + '/zinplay',
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "Teaching Promotion_ZIN Play App",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.zinPlayAppText), 11, _hoisted_72)
                                                ]),
                                                (props.isClassBuzzSplashEnabled)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_73, [
                                                        _createElementVNode("a", {
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Teach']?.['/classbuzz'] }]),
                                                            href: '/' + props.currentUserLocale + '/classbuzz',
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Classbuzz_App",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.classBuzzAppText), 11, _hoisted_74)
                                                    ]))
                                                    : _createCommentVNode("", true)
                                            ], 4)
                                        ]))
                                        : _createCommentVNode("", true),
                                    (props.canAccessMemberMenu)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_75, [
                                            _createElementVNode("a", {
                                                class: _normalizeClass(["more-below ocn-list-link", {
                                                        'js-active-item inactive-item': !isKeyEmpty(props.active?.['Learn']),
                                                        'pre-selected-active': !isKeyEmpty(props.active?.['Learn'])
                                                    }]),
                                                href: "#"
                                            }, _toDisplayString(props.t.trainingsEducationText), 3),
                                            _createElementVNode("ul", {
                                                class: "ocn-subnav subnav-nested",
                                                style: _normalizeStyle(!isKeyEmpty(props.active?.['Learn']) ? 'display: block;' : '')
                                            }, [
                                                _createElementVNode("li", _hoisted_76, [
                                                    (props.hasPreMembershipLinksOnly && props.restrictedInstructorTraining)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_77, _toDisplayString(props.t.instructorTrainingsText), 1))
                                                        : (_openBlock(), _createElementBlock("a", {
                                                            key: 1,
                                                            href: '/' + props.currentUserLocale + '/training',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/training'] }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Trainings Education_Instructor Trainings",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.instructorTrainingsText), 11, _hoisted_78))
                                                ]),
                                                (props.isMembershipCirclInstructorNavigationEnabled)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_79, [
                                                        _createElementVNode("a", {
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/circl-mobility-instructor'] }]),
                                                            href: '/' + props.currentUserLocale + '/circl-mobility-instructor',
                                                            "data-ga-category": "ZUMBA Home",
                                                            "data-ga-action": `${_ctx.$props.isMembershipTypeZumba ? 'CIRCL_Mobility_ZIN' : 'CIRCL_Mobility_SYNC'}`
                                                        }, [
                                                            (props.isMembershipCirclInstructorNavigationNewEnabled && !props.isNoomCertificationEnabled)
                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_81, " CIRCL Mobility™ "))
                                                                : _createCommentVNode("", true),
                                                            (props.isMembershipCirclInstructorNavigationNewEnabled && !props.isNoomCertificationEnabled)
                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_82, [
                                                                    _createVNode(Tag, {
                                                                        variety: "small",
                                                                        "show-cancel": false,
                                                                        class: "new-feature-tag"
                                                                    }, {
                                                                        default: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString(props.t.newFeature), 1)
                                                                        ]),
                                                                        _: 1
                                                                    })
                                                                ]))
                                                                : _createCommentVNode("", true),
                                                            (!props.isMembershipCirclInstructorNavigationNewEnabled || props.isNoomCertificationEnabled)
                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_83, " CIRCL Mobility™ "))
                                                                : _createCommentVNode("", true)
                                                        ], 10, _hoisted_80)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                (props.userHasBenefitStrongInstructorAccess)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_84, _cache[1] || (_cache[1] = [
                                                        _createElementVNode("a", {
                                                            class: "ocn-list-link",
                                                            href: "https://strong.zumba.com/en-US/become-an-instructor",
                                                            "data-ga-category": "ZUMBA Home",
                                                            "data-ga-action": "STRONG_Nation_become_an_instructor"
                                                        }, " STRONG Nation™ ", -1)
                                                    ])))
                                                    : _createCommentVNode("", true),
                                                _createElementVNode("li", _hoisted_85, [
                                                    (props.isMenuLinksEnabled && __props.hasPreMembershipLinksOnly)
                                                        ? (_openBlock(), _createElementBlock("a", {
                                                            key: 0,
                                                            href: '/' + props.currentUserLocale + '/mentor',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/mentor'] }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Trainings Education_Mentor Program",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.mentorProgramText), 11, _hoisted_86))
                                                        : (props.isMenuLinksEnabled && props.userHasBenefitUseZinMentorProgram)
                                                            ? (_openBlock(), _createElementBlock("a", {
                                                                key: 1,
                                                                href: '/' + props.currentUserLocale + '/mentor/dashboard',
                                                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/mentor/dashboard'] }]),
                                                                "data-ga-category": "Navigation Menu",
                                                                "data-ga-action": "Trainings Education_Mentor Program",
                                                                "data-ga-label": props.gaLabel
                                                            }, _toDisplayString(props.t.mentorProgramText), 11, _hoisted_87))
                                                            : _createCommentVNode("", true)
                                                ]),
                                                (props.isZinHomeAcademyLinksEnabled)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_88, [
                                                        _createElementVNode("a", {
                                                            class: "ocn-list-link",
                                                            href: "//convention.zumba.com",
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Trainings Education_Convention",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.zumbaConventionText), 9, _hoisted_89)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                (props.isZinHomeAcademyLinksEnabled)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_90, [
                                                        _createElementVNode("a", {
                                                            class: "ocn-list-link",
                                                            href: "//academies.zumba.com",
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Trainings Education_Academies",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.instructorAcademiesText), 9, _hoisted_91)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                (props.userHasBenefitMemberELearning && !props.hideJapaneseMenu)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_92, [
                                                        (props.hasPreMembershipLinksOnly)
                                                            ? (_openBlock(), _createElementBlock("span", _hoisted_93, _toDisplayString(props.t.eLearningText), 1))
                                                            : (_openBlock(), _createElementBlock("a", {
                                                                key: 1,
                                                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/member/elearning'] }]),
                                                                href: '/' + props.currentUserLocale + '/member/elearning'
                                                            }, _toDisplayString(props.t.eLearningText), 11, _hoisted_94))
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                (!props.hideJapaneseMenu)
                                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                                        ((props.isAfaaDashboardEnabled && props.hasPreMembershipLinksOnly))
                                                            ? (_openBlock(), _createElementBlock("li", _hoisted_95, [
                                                                _createElementVNode("span", _hoisted_96, _toDisplayString(props.t.afaaGfiForZinText), 1)
                                                            ]))
                                                            : (props.isAfaaDashboardEnabled)
                                                                ? (_openBlock(), _createElementBlock("li", _hoisted_97, [
                                                                    _createElementVNode("a", {
                                                                        href: '/' + props.currentUserLocale + '/member/afaa',
                                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/member/afaa'] }]),
                                                                        "data-ga-category": props.membershipTypeSlug + ' Home',
                                                                        "data-ga-action": "AFAA"
                                                                    }, _toDisplayString(props.t.afaaGfiForZinText), 11, _hoisted_98)
                                                                ]))
                                                                : _createCommentVNode("", true)
                                                    ], 64))
                                                    : _createCommentVNode("", true),
                                                (showNoom.value)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_99, [
                                                        ((props.isNoomCertificationEnabled && props.hasPreMembershipLinksOnly))
                                                            ? (_openBlock(), _createElementBlock("span", _hoisted_100, _toDisplayString(props.t.noomCoachingText), 1))
                                                            : (props.isNoomCertificationEnabled)
                                                                ? _renderSlot(_ctx.$slots, "noom-link", { key: 1 })
                                                                : _createCommentVNode("", true)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                ((props.isMembershipTypeZumba && props.isPoseSideMenuEnabled && props.userHasBenefitPoseChallengeAccess))
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_101, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/step-challenge',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/step-challenge'] }]),
                                                            "data-ga-category": "ZUMBA Home",
                                                            "data-ga-action": "Step_Challenge"
                                                        }, _toDisplayString(props.t.stepChallengeText), 11, _hoisted_102)
                                                    ]))
                                                    : _createCommentVNode("", true)
                                            ], 4)
                                        ]))
                                        : _createCommentVNode("", true),
                                    (props.canAccessMemberMenu)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_103, [
                                            _createElementVNode("a", {
                                                class: _normalizeClass(["more-below ocn-list-link", {
                                                        'js-active-item inactive-item': !isKeyEmpty(props.active?.['Earn']),
                                                        'pre-selected-active': !isKeyEmpty(props.active?.['Earn'])
                                                    }]),
                                                href: "#"
                                            }, _toDisplayString(props.t.earningsSupportText), 3),
                                            (props.isTippingLandingLinkLeftMenuEnabled)
                                                ? (_openBlock(), _createElementBlock("ul", {
                                                    key: 0,
                                                    class: "ocn-subnav subnav-nested",
                                                    style: _normalizeStyle(!isKeyEmpty(props.active?.['Earn']) ? 'display: block;' : '')
                                                }, [
                                                    _createElementVNode("li", _hoisted_104, [
                                                        _createElementVNode("a", {
                                                            href: '/' + props.currentUserLocale + '/member/tipping',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/member/tipping'] }]),
                                                            "data-ga-category": "Member Home",
                                                            "data-ga-action": "Tipping"
                                                        }, _toDisplayString(props.t.tippingText), 11, _hoisted_105)
                                                    ])
                                                ], 4))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("ul", {
                                                class: "ocn-subnav subnav-nested",
                                                style: _normalizeStyle(!isKeyEmpty(props.active?.['Earn']) ? 'display: block;' : '')
                                            }, [
                                                _createElementVNode("li", _hoisted_106, [
                                                    (props.hasPreMembershipLinksOnly && props.restrictedReferFriend)
                                                        ? (_openBlock(), _createElementBlock("span", {
                                                            key: 0,
                                                            class: "ocn-list-item__no-access ocn-list-link",
                                                            innerHTML: props.t.referFriendText
                                                        }, null, 8, _hoisted_107))
                                                        : (_openBlock(), _createElementBlock("span", _hoisted_108, [
                                                            (props.newReferralProgram && props.showShareAndEarn)
                                                                ? (_openBlock(), _createElementBlock("a", {
                                                                    key: 0,
                                                                    href: '/' + props.currentUserLocale + '/training/share_and_earn',
                                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/training/share_and_earn'] }]),
                                                                    "data-ga-category": "Navigation Menu",
                                                                    "data-ga-action": "Refer_A_Friend",
                                                                    "data-ga-label": props.gaLabel,
                                                                    innerHTML: props.t.referFriendText
                                                                }, null, 10, _hoisted_109))
                                                                : _createCommentVNode("", true),
                                                            (!props.newReferralProgram)
                                                                ? (_openBlock(), _createElementBlock("a", {
                                                                    key: 1,
                                                                    href: '/' + props.currentUserLocale + '/training/refer',
                                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/training/refer'] }]),
                                                                    "data-ga-category": "Navigation Menu",
                                                                    "data-ga-action": "Refer_A_Friend",
                                                                    "data-ga-label": props.gaLabel,
                                                                    innerHTML: props.t.referFriendText
                                                                }, null, 10, _hoisted_110))
                                                                : _createCommentVNode("", true)
                                                        ]))
                                                ]),
                                                _createElementVNode("li", _hoisted_111, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/member/zwinsider',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/member/zwinsider'] }]),
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "Earnings Support_ZumbaWear Insiders",
                                                        "data-ga-label": props.gaLabel,
                                                        innerHTML: props.t.zumbaRegWearInsiderText
                                                    }, null, 10, _hoisted_112)
                                                ]),
                                                (props.userHasBenefitZinDiscounts)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_113, [
                                                        _createElementVNode("a", {
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/member/earn-discount'] }]),
                                                            href: '/' + props.currentUserLocale + '/member/earn-discount',
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Earnings Support_Discounts",
                                                            "data-ga-label": props.gaLabel
                                                        }, _toDisplayString(props.t.discountsText), 11, _hoisted_114)
                                                    ]))
                                                    : _createCommentVNode("", true)
                                            ], 4)
                                        ]))
                                        : _createCommentVNode("", true),
                                    (props.canAccessMemberMenu)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_115, [
                                            _createElementVNode("a", {
                                                href: "#",
                                                class: _normalizeClass(["more-below ocn-list-link", {
                                                        'js-active-item inactive-item': !isKeyEmpty(props.active?.['Connect']),
                                                        'pre-selected-active': !isKeyEmpty(props.active?.['Connect'])
                                                    }])
                                            }, _toDisplayString(props.t.communityText), 3),
                                            _createElementVNode("ul", {
                                                class: "ocn-subnav subnav-nested",
                                                style: _normalizeStyle(!isKeyEmpty(props.active?.['Connect']) ? 'display: block;' : '')
                                            }, [
                                                (props.userHasBenefitZumbaForumAccess || props.hasPreMembershipLinksOnly)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_116, [
                                                        (props.hasPreMembershipLinksOnly)
                                                            ? (_openBlock(), _createElementBlock("span", _hoisted_117, _toDisplayString(props.t.forumText), 1))
                                                            : (_openBlock(), _createElementBlock("div", _hoisted_118, [
                                                                _createElementVNode("a", {
                                                                    class: "ocn-list-link",
                                                                    href: props.isProduction ? 'https://www.zumba.community/' : 'https://community.staging.zumba.com/',
                                                                    "data-ga-category": "Navigation Menu",
                                                                    "data-ga-action": "Community Support_Forum",
                                                                    "data-ga-label": props.gaLabel
                                                                }, _toDisplayString(props.t.forumText), 9, _hoisted_119),
                                                                _createElementVNode("a", {
                                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Connect']?.['/zcom'] }]),
                                                                    href: props.isProduction ? 'https://www.zumba.com/zcom' : 'https://www.development.zumba.com/zcom',
                                                                    "data-ga-category": "Navigation Menu",
                                                                    "data-ga-action": "Community ZCOM Volunteers",
                                                                    "data-ga-label": props.gaLabel
                                                                }, _toDisplayString(props.t.zcomVolunteersText), 11, _hoisted_120)
                                                            ]))
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                _createVNode(Gym, {
                                                    active: props.active,
                                                    t: props.t,
                                                    "current-user-locale": props.currentUserLocale,
                                                    "ga-label": props.gaLabel,
                                                    "can-manage-gym-profile": props.canManageGymProfile,
                                                    "can-manage-linked-gym-instructors": props.canManageLinkedGymInstructors
                                                }, null, 8, ["active", "t", "current-user-locale", "ga-label", "can-manage-gym-profile", "can-manage-linked-gym-instructors"])
                                            ], 4)
                                        ]))
                                        : _createCommentVNode("", true)
                                ], 4)
                            ]))
                            : _createCommentVNode("", true),
                        (!props.isGuestUser)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_121, [
                                ((props.canManageGymProfile && props.canAccessMemberMenu))
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/profile'] }]),
                                        href: '/' + props.currentUserLocale + props.zinProfileAccountLink,
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "My Account",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.t.manageMyAccountText), 11, _hoisted_122))
                                    : (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/profile'] }]),
                                        href: '/' + props.currentUserLocale + '/' + props.zinProfileAccountLink,
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Account",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.t.myAccountText), 11, _hoisted_123)),
                                (props.canViewStudentDashboard)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 2,
                                        id: "hamburger-link-student-dashboard",
                                        href: '/' + props.currentUserLocale + '/student_dashboard',
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/student_dashboard'] }]),
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "{{studentDashboardText}}"
                                    }, _toDisplayString(studentDashboardText.value), 11, _hoisted_124))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true),
                        (props.userHasDelinquentMembership)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                _createElementVNode("li", _hoisted_125, [
                                    _createElementVNode("a", {
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/profile'] }]),
                                        href: '/' + props.currentUserLocale + '/' + props.zinProfileAccountLink,
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Tools_My Account",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.t.myAccountText), 11, _hoisted_126)
                                ]),
                                _createElementVNode("li", _hoisted_127, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/training/history',
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Tools']?.['/training'] }]),
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Tools_My Trainings",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.dashboardMenuItemsEnabled
                                        ? props.t.myTrainingsProgramText
                                        : props.t.myTrainingsText), 11, _hoisted_128)
                                ])
                            ], 64))
                            : _createCommentVNode("", true)
                    ], 64))
                    : _createCommentVNode("", true),
                (!props.isSixWeekHub)
                    ? (_openBlock(), _createBlock(Classes, {
                        key: 1,
                        active: props.active,
                        t: props.t,
                        "current-user-locale": props.currentUserLocale,
                        "ga-label": props.gaLabel,
                        "is-six-week-launch-enabled": props.isSixWeekLaunchEnabled,
                        "online-class-base-url": props.onlineClassBaseUrl,
                        "six-week-splash-page-url": props.sixWeekSplashPageUrl,
                        "class-locator-enabled": props.classLocatorEnabled,
                        "is-circl-url": props.isCirclUrl,
                        "is-zumba-app-menu-launch-enabled": props.isZumbaAppMenuLaunchEnabled,
                        "is-job-board-form-enabled": props.isJobBoardFormEnabled,
                        "is-gym-post-a-job-enabled": props.isGymPostAJobEnabled
                    }, null, 8, ["active", "t", "current-user-locale", "ga-label", "is-six-week-launch-enabled", "online-class-base-url", "six-week-splash-page-url", "class-locator-enabled", "is-circl-url", "is-zumba-app-menu-launch-enabled", "is-job-board-form-enabled", "is-gym-post-a-job-enabled"]))
                    : _createCommentVNode("", true),
                (props.isZumbaAppMenuLaunchEnabled && !props.isZumbaAppNoMenuButtonEnabled)
                    ? (_openBlock(), _createBlock(ZumbaApp, {
                        key: 2,
                        active: props.active,
                        t: props.t,
                        "current-user-locale": props.currentUserLocale,
                        "ga-label": props.gaLabel,
                        "zumba-app-store-url": getAppUrl.value,
                        "virtual-plus-subscription-url": props.virtualPlusSubscriptionUrl
                    }, null, 8, ["active", "t", "current-user-locale", "ga-label", "zumba-app-store-url", "virtual-plus-subscription-url"]))
                    : _createCommentVNode("", true),
                (props.isZumbaAppNoMenuButtonEnabled)
                    ? (_openBlock(), _createBlock(ZumbaAppButton, {
                        key: 3,
                        "is-zin": props.isZin,
                        t: props.t,
                        "ga-label": props.gaLabel
                    }, null, 8, ["is-zin", "t", "ga-label"]))
                    : _createCommentVNode("", true),
                (!props.isSixWeekHub)
                    ? (_openBlock(), _createBlock(Instructors, {
                        key: 4,
                        active: props.active,
                        t: props.t,
                        "user-has-benefit-specialty-training-access": props.userHasBenefitSpecialtyTrainingAccess,
                        "is-bot-bai-coronavirus-enabled": props.isBotBaiCoronavirusEnabled,
                        "is-maximizeb1-sitenav-trainingtypes-enabled": props.isMaximizeb1SitenavTrainingtypesEnabled,
                        "is-online-country-us": props.isOnlineCountryUs,
                        "current-user-locale": props.currentUserLocale,
                        "ga-label": props.gaLabel
                    }, {
                        sessions: _withCtx(() => [
                            _createVNode(Sessions, {
                                t: props.t,
                                "is-authenticated": props.isAuthenticated,
                                "has-pre-membership-links-only": props.hasPreMembershipLinksOnly,
                                active: props.active,
                                "current-user-locale": props.currentUserLocale,
                                "ga-label": props.gaLabel
                            }, null, 8, ["t", "is-authenticated", "has-pre-membership-links-only", "active", "current-user-locale", "ga-label"])
                        ]),
                        _: 1
                    }, 8, ["active", "t", "user-has-benefit-specialty-training-access", "is-bot-bai-coronavirus-enabled", "is-maximizeb1-sitenav-trainingtypes-enabled", "is-online-country-us", "current-user-locale", "ga-label"]))
                    : _createCommentVNode("", true),
                (!props.isSixWeekHub)
                    ? (_openBlock(), _createBlock(Shop, {
                        key: 5,
                        t: props.t,
                        "navigation-shop-url": props.navigationShopUrl
                    }, null, 8, ["t", "navigation-shop-url"]))
                    : _createCommentVNode("", true),
                _createVNode(About, {
                    active: props.active,
                    t: props.t,
                    "current-user-locale": props.currentUserLocale,
                    "ga-label": props.gaLabel
                }, null, 8, ["active", "t", "current-user-locale", "ga-label"]),
                _renderSlot(_ctx.$slots, "default")
            ], 64));
        };
    }
});
